import React from 'react'

export default function Faq() {
  return (
    <div className='faq__page'>
      <h2 className='marginTop2'>Jak zamawiać?</h2>
      <p>Aby zamówić produkt w naszym sklepie należy wybrać jeden z nich, podać ilość sztuk za pośrednictwem przeznaczonego do tego pola, a następnie kliknąć Dodaj do koszyka.</p>
<p>Kolejnym krokiem jest podanie swoich danych w odpowiednim miejscu, w przypadku zamówienia na fakturę należy zaznaczyć odpowieni cheackboks i dodatkowo podać dane firmowe wraz z numerem NIP. </p>
<p>W kolejnym kroku należy podać dane do wysyłki, w przypadku wyboru dostawy do paczkomatu, w późniejszym kroku będzie miejsce, gdzie należy wybrać odpowiedni Paczkomat.</p>
<p>Wybieramy następnie formę dostawy oraz formę płatności. Formy płatności i wysyłki opisane są na odpowiadających temu stronach. Postępujemy zgodnie z instrukcjami i zatwierdzamy zamówienie.</p>
<p>Bardzo ważne jest aby dokonując zamówień na naszej stronie podać aktualny i poprawny adres e-mail, ponieważ on służy nam do bezpośredniego kontaktu.</p>
<p>Zamawiając w naszym sklepie masz możliwość założenia konta, nie jest to obowiązkowe do dokonania zakupu, ale może pomóc w kolejnych zakupach, jak również pozwoli mieć wgląd do historii i statusów zamówień.</p>
<p>Konto założone w sklepie nie jest tym samym kontem, które jest zakładane w przypadku kupowania szkoleń. Dla platformy edukacyjnej jest wymagane inne konto.</p>
    </div>
  )
}
