import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from "react-router"
import Axios from "axios"

import { useForm } from 'react-hook-form'

import { resetShema } from '../controllers/ValidationShemas'

import FormInput from "./FormInput"

import { apiUrl } from '../Vars'

export default function ResetFormAfter({ token }) {

  const [ errMsg, setErrMsg ] = useState(null)

  const { register, handleSubmit, formState } = useForm({ resolver: zodResolver(resetShema)});

  const { errors } = formState

  const password = {name: 'password', label: 'Podaj swoje nowe hasło', options: '', type: 'password'}
  const repeatPassword = {name: 'repeatPassword', label: 'Powtórz swoje nowe hasło', options: '', type: 'password'}

  const navigate = useNavigate()

  const submitPassword = (data) => {
    console.log(data)
    const newPassword = data.password
    Axios.post(
      `${apiUrl}/api/password-reset`,
      { newPassword },
      {
        headers: {
          "x-access-token" : `${token}`
        },
        withCredentials: true
      })
      .then((response) => {
      console.log(response.data)
      navigate('/login?msg=reset-ok')
    }).catch((err) => {
      console.log(err.response.data)
      setErrMsg(err.response.data.msg)
    })
  }

  const showError = (errors) => {
    console.log(errors)
  }

  return (
    <div>
      {errMsg !== null ? <p style={{ color: 'red' }}>{errMsg}</p> : <></>}
      <form action="/" className="form" onSubmit={handleSubmit(submitPassword, showError)}>
        <FormInput controls={password} register={register}/>
        <span className="input__err">{errors.password?.message}</span>
        <FormInput controls={repeatPassword} register={register}/>
        <span className="input__err">{errors.repeatPassword?.message}</span>
        <button type="submit" className="btn">Ustaw hasło</button>
      </form>
      <br />
    </div>
  )
}
