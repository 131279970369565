import { NavLink } from "react-router-dom"

export default function NavigationAlt() {
	return(
		<div className="navigation">
      <ul className="navigation__list">
        <li key='1'><NavLink to='/faq'>Jak zamawiać</NavLink></li>
        <li key='2'><NavLink to='/payments'>Metody płatności</NavLink></li>
        <li key='3'><NavLink to='/shipment'>Metody wysyłki</NavLink></li>
        <li key='4'><NavLink to='/rejestracja'>Rejestracja</NavLink></li>
        <li key='5'><NavLink to='/terms'>Regulamin</NavLink></li>
        <li key='6'><NavLink to='/cookies'>Polityka prywatności</NavLink></li>
        <li key='7'><NavLink to='/pomoc'>Pomoc</NavLink></li>
		  </ul>
    </div>
	)
}