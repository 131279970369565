import React from "react";
import Warning from "./Warning";

export default function WarningPop({ setActiveWarning }) {
  const closePop = () => {
    console.log("closePop");
    setActiveWarning(false);
  };

  return (
    <div className="warningPop">
      <div className="warningPop__content">
        <Warning />
        <button
          className="btn"
          onClick={() => closePop()}
        >
          Rozumiem
        </button>
      </div>
    </div>
  );
}
