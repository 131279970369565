const apiUrl = process.env.REACT_APP_API_URL

const countries = [ { value: "" }, { name: "Polska", value: "PL" }, { name: "Wielka Brytania", value: "GB" }, { value: "AT", name: "Austria" }, { value: "BE", name: "Belgia" }, { value: "BG", name: "Bułgaria" }, { value: "HR", name: "Chorwacja" }, { value: "CY", name: "Cypr" }, { value: "CZ", name: "Czechy" }, { value: "DK", name: "Dania" }, { value: "EE", name: "Estonia" }, { value: "FI", name: "Finlandia" }, { value: "FR", name: "Francja" }, { value: "GR", name: "Grecja" }, { value: "ES", name: "Hiszpania" }, { value: "IE", name: "Irlandia" }, { value: "LT", name: "Litwa" }, { value: "LU", name: "Luksemburg" }, { value: "LV", name: "Łotwa" }, { value: "MT", name: "Malta" }, { value: "NL", name: "Holandia" }, { value: "DE", name: "Niemcy" }, { value: "PT", name: "Portugalia" }, { value: "RO", name: "Rumunia" }, { value: "SK", name: "Słowacja" }, { value: "SI", name: "Słowenia" }, { value: "SE", name: "Szwecja" }, { value: "HU", name: "Węgry" }, { value: "IT", name: "Włochy" }, ];

const bAdress = {
  email:"",
  repeatEmail:"",
  name:"",
  surname:"",
  companyName:"",
  taxNumber:"",
  street:"",
  addressNumber1:"",
  addressNumber2:"",
  city:"",
  zipCode:"",
  country:"",
  phone:"",
  isCompany:""
}

const sAdress = {
  email:"",
  repeatEmail:"",
  name:"",
  surname:"",
  companyName:"",
  taxNumber:"",
  street:"",
  addressNumber1:"",
  addressNumber2:"",
  city:"",
  zipCode:"",
  country:"",
  phone:"",
  isCompany:""
}

export { apiUrl, bAdress, sAdress, countries }