import React from "react";

export default function Warning() {
  return (
    <div>
      <h3 className="red">Uwaga!</h3>
      <p>
        Wskutek powstałego błędu drukarskiego, na opakowaniach produktów: <strong>Gen Factor No 05, Gen Factor No 06, Gen Factor No 07, Gen Factor No 08, Gen Factor No 09</strong>, widnieje niepoprawna data ważności. Poprawna data ważności jest o <strong>12 miesięcy dłuższa</strong>. Jako producent
        dajemy gwarancję w tym zakresie.
      </p>
      <p>Gwarancja w formie drukowanej będzie dołączona do przesyłki.</p>
    </div>
  );
}
