import Axios from "axios";

import { apiUrl } from "../Vars";

const devState = process.env.REACT_APP_DEVELOPER_STATE === "true" ? true : false;

const placeOrder = (data, orderObject) => {
  const o = orderObject;

  devState && console.log("orderObject");
  devState && console.log(o);

  o.setIdle(true);

  const billingData = {
    email: o.user.user.email,
    repeatEmail: o.user.user.email,
    name: data.name,
    surname: data.surname,
    companyName: o.isCompany ? data.companyName : null,
    taxNumber: o.isCompany ? data.taxNumber : null,
    street: data.street,
    addressNumber1: data.addressNumber1,
    addressNumber2: data.addressNumber2,
    city: data.city,
    zipCode: data.zipCode,
    country: data.country,
    phone: data.phone,
  };

  const invoice = {
    type: "normal",
    type_of_sale: "",
    price_type: data.country === "PL" ? "brutto" : "netto",
    currency: "PLN",
    paymentstate: "",
    paymentdate: "",
    alreadypaid_initial: "",
    paymentId: "",
    buyer_type: o.isCompany ? "company" : "personal",
    paymentMethod: data.payment_method,
    discountName: o.discountObject.name,
    discountValue: o.discountObject.value,
    buyer_country: data.country,
  };

  if (o.point) {
    const shippingData = {
      name: o.differentShipping ? data.shippingName : data.name,
      surname: o.differentShipping ? data.shippingSurname : data.surname,
      companyName: o.differentShipping && o.isCompany ? data.shippingCompanyName : null, //TODO: check if this is correct
      street: o.point.street,
      addressNumber1: o.point.building_number,
      addressNumber2: o.point.flat_number,
      pointName: o.point.name,
      city: o.point.city,
      zipCode: o.point.post_code,
      country: "pl",
      phone: o.differentShipping ? data.shippingPhone : data.phone,
    };
    createOrder(billingData, shippingData, data.orderInfo, invoice, o);
  } else {
    const shippingData = {
      name: o.differentShipping ? data.shippingName : data.name,
      surname: o.differentShipping ? data.shippingSurname : data.surname,
      companyName: o.differentShipping && o.isCompany ? data.shippingCompanyName : null,
      street: o.differentShipping ? data.shippingStreet : data.street,
      addressNumber1: o.differentShipping ? data.shippingAddressNumber1 : data.addressNumber1,
      addressNumber2: o.differentShipping ? data.shippingAddressNumber2 : data.addressNumber2,
      pointName: null,
      city: o.differentShipping ? data.shippingCity : data.city,
      zipCode: o.differentShipping ? data.shippingZipCode : data.zipCode,
      country: o.differentShipping ? data.shippingCountry : o.userCountry,
      phone: o.differentShipping ? data.shippingPhone : data.phone,
    };
    createOrder(billingData, shippingData, data.orderInfo, invoice, o);
  }
};

const randomUserId = parseInt(Math.random(10000, 100000) * 1000000);

const createOrder = (billingData, shippingData, orderInfo, invoice, o) => {
  const order = {
    userId: o.user.user.id ? o.user.user.id : randomUserId,
    userType: o.user.user.userType,
    userCountry: o.userCountry,
    userBillingData: billingData,
    userShippingData: shippingData,
    differentShipping: o.differentShipping,
    orderItems: o.userCart,
    orderValue: o.orderValue,
    shippingMethod: o.shippingMethod,
    shippingCost: o.shippingCost,
    userIsCompany: o.isCompany,
    paymentMethod: invoice.paymentMethod,
    paymentStatus: "PENNDING",
    orderInfo: orderInfo,
    invoice: invoice,
    cart_id: o.cart_id,
    type: "PRO",
    refParam: o.referralUserParam,
  };

  devState && console.log("order");
  devState && console.log(order);

  Axios.post(`${apiUrl}/api/create-order`, {
    order,
  })
    .then((response) => {
      localStorage.removeItem("userCart");
      sessionStorage.removeItem("discount");
      o.setIdle(false);
      console.log(response);
      window.location.replace(response.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export { placeOrder };
