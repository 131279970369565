// import TaxValidationField from "./FormFields/TaxValidationField"
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
// import TaxValidationField from "./FormFields/TaxValidationField";

import { countries } from "../Vars";

export default function BillingForm({ register, errors, companyToggle, isCompany, location, countryChange }) {
  // const email = {name: 'email', label: 'Adres email', type: 'text', options: ''}
  // const repeatEmail = {name: 'repeatEmail', label: 'Powtórz adres email', options: ''}
  const firstName = { name: "name", label: "Imię", options: "" };
  const lastName = { name: "surname", label: "Nazwisko", options: "" };
  const companyName = { name: "companyName", label: "Nazwa Firmy", options: "" };
  const taxNumber = { name: "taxNumber", label: "numer NIP/TAX", options: "" }
  const street = { name: "street", label: "Ulica / Miejscowość", options: "" };
  const addressNumber1 = { name: "addressNumber1", label: "Numer budynku", type: "text", options: "", };
  const addressNumber2 = { name: "addressNumber2", label: "Numer lokalu", type: "text", options: "", };
  const zipCode = { name: "zipCode", label: "Kod pocztowy", options: "" };
  const city = { name: "city", label: "Miasto / Poczta", options: "" };
  const country = { name: "country", label: "Kraj", options: countries }
  const phone = { name: "phone", label: "Numer telefonu", type: "text", options: "", };

  return (
    <div className="order_form">
      {location === "orderPage" ? (
        <>
          {/* <FormInput controls={email} register={register}/>
          <span className="input__err">{errors.email?.message}</span>
          <FormInput controls={repeatEmail} register={register}/>
          <span className="input__err">{errors.repeatEmail?.message}</span> */}
        </>
      ) : (
        <></>
      )}
      <FormInput controls={firstName} register={register} />
      <span className="input__err">{errors.name?.message}</span>
    
      <FormInput controls={lastName} register={register} />
      <span className="input__err">{errors.surname?.message}</span>

      <FormInput controls={companyName} register={register} />
      <span className="input__err">{errors.name?.companyName}</span>
     
      {/* {location !== 'userPage' ?
      <div className="input__line__checkbox">
        <input type="checkbox" name="is_company" id="is_company" {...register("is_company")} onChange={companyToggle}/>
        <label htmlFor="is_company">Chcę otrzymać fakturę vat</label>
      </div> : <></>} */}
      {/* {location === 'userPage' ?
      <div className="input__line__checkbox">
        <input type="checkbox" name="is_company" id="is_company" {...register("is_company")} onChange={companyToggle}/>
        <label htmlFor="is_company">Klient instytucjonalny</label>
      </div> : <></>} */}

      <FormInput controls={taxNumber} register={register}/>
      <span className="input__err">{errors.taxNumber?.message}</span>
      <p className="field-description">
        Wpisz numer NIP/VAT bez przedrostka swojego kraju
      </p>

      <FormInput controls={street} register={register} />
      <span className="input__err">{errors.street?.message}</span>

      <div className="input__line">
        <div className="input__box">
          <FormInput controls={addressNumber1} register={register} />
          <span className="input__err">{errors.addressNumber1?.message}</span>
        </div>
        <div className="input__box">
          <FormInput controls={addressNumber2} register={register} />
          <span className="input__err">{errors.addressNumber2?.message}</span>
        </div>
      </div>

      <div className="input__line">
        <div className="input__box">
          <FormInput controls={zipCode} register={register} />
          <span className="input__err">{errors.zipCode?.message}</span>
        </div>
        <div className="input__box">
          <FormInput controls={city} register={register} />
          <span className="input__err">{errors.city?.message}</span>
        </div>
      </div>
      
      <FormSelect controls={country} register={register} countryChange={countryChange}/>
      <span className="input__err">{errors.country?.message}</span>
      
      <FormInput controls={phone} register={register} />
      <span className="input__err">{errors.phone?.message}</span>
    
      {location === "orderPage" ? (
        <>
          <div className="form__input">
            <label htmlFor="orderInfo">Uwagi do zamówienia</label>
            <textarea name="orderInfo" id="orderInfo" cols="30" rows="10" {...register("orderInfo")} ></textarea>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
