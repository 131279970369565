import React from 'react'
import MobileMenu from './MobileMenu'

export default function Modal({ isModal, handleModal }) {
  return (
    <div className={`modal ${isModal ? 'visible' : 'hidden'}`} onClick={() => handleModal()}>
      <MobileMenu />
    </div>
  )
}
