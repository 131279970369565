
import Navigation from './Navigation'
import NavigationAlt from './NavigationAlt';
import Logo from './Logo'

export default function Footer() {

  return (
    <>
      <>
        <br></br>
        <hr></hr>
        <div className='footer'>
          <div className='footer__inner'>
          <div className='footer__seo footer__part'>
            <Logo />
            <p>Gen Factor to seria kosmetyków i preperatów do profesjonalnych zastosowań pielęgnacji gabinetowej.</p>
          </div>
          <div className='footer__navigation footer__part'>
          <h3>Menu</h3>
            <Navigation />
          </div>
          <div className='footer__navigation footer__part'>
          <h3>Na skróty</h3>
            <NavigationAlt />
          </div>
          <div className='footer__info footer__part'>
            <h3>Saintlazarus SP. z o.o.</h3>
            <p>ul. Pocztowa 4</p>
            <p>21-300 Radzyń Podlaski</p>
            <p>NIP 5381859985</p>
            <p>KRS 0000784131</p>
          </div>
          <div className='footer__info footer__part'>
            
          </div>
        </div>
        </div>
      </>
    </>
  )
}
