import { useState, useEffect } from "react"

import Axios from 'axios'

import { apiUrl } from "../Vars"

export const useGetProducts = (categories) => {
  
  const [ prods, setProds ] = useState([]);
  const [ isPennding, setIsPennding ] = useState(true);
  const [ err, setErr ] = useState(null);

  useEffect(() => {
    Axios.post(`${apiUrl}/api/products-cat`, {categories})
      .then(res => {
        setProds(res.data);
        setIsPennding(false);
        setErr(null);
      })
      .catch(err => {
        setIsPennding(false);
        setErr(err.message);
      })
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  return { prods, isPennding, err }
}