import Button from './Button';

export default function ErrorDisplay() {
  return (
    <div className="error-display flex row j-center a-center g3 m-column">
      <div className="error-display__form w50 pl1 pr1">
        <h3 className="s3 primary">Ooops... Coś poszło nie tak...</h3>
        <p className="s1_5 mb2">Mamy chwilowe problemy z wyświetleniem tej zawartości. Prosimy spróbuj później, a jeśli problem będzie nadal występował możesz zgłośić to do naszego działu technicznego. Oni naprawią usterkę najszybciej, jak to możliwe.</p>
        <Button content={'Wyślij zgłoszenie awarii'} action={console.log} option={'awaria'} alert={'Wysłano zgłoszenie'}/>
      </div>
      <div className="login-monit__img">
        <img src="https://shop.genfactor.pl/assets/error-page.png" alt="zaloguj się" />
      </div>
    </div>
  )
}
