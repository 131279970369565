import React from 'react'

export default function Shipment() {
  return (
    <div className='shipment__page'>
      <h2>Metody i czas wysyłki</h2>
      <p>W naszym sklepie są dostępne dwie formy wysylki. Dostawa kurierem InPost oraz dostawa do paczkomatów InPost. W przypadku dostawy kurierem będzie wykorzystany adres dostawy podany w trakcie składania zamówienia, w przypadku dostawy do paczkomatu hakerzy w odpowiednim miejscu wskazać docelowy paczkomat.</p>
<p>Czas realizacji zamówienia jest zależny od rodzaju wybranej płatności i formy dostawy. Zazwyczaj wysyłamy towar na drugi dzień roboczy od momentu zaksięgowania wpłaty na nasz rachunek, do tego należy doliczyć czas dostawy przez firmę kurierską, czas ten może być uzależniony od aktualnych warunków logistycznych.</p>
		
    </div>
  )
}
