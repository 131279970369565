

export default function Terms() {
  return (
    <div className="terms">
      <h2 className="marginTop2">Regulamin</h2>
      <p>Dzień dobry!<br />
Nazywam się Wojciech Karwowski i jestem twórcą sklepu dostępnego pod adresem https://shop.genfactor.pl. Jest mi niezwykle miło, że obdarzyłeś mnie swoim zaufaniem i jesteś zainteresowany zakupami w moim sklepie.</p>
<p>Gwoli formalności, dane rejestrowe spółki: Saint Lazarus Sp. z o.o., ul. Pocztowa 4, 21-300 Radzyń Podlaski, NIP 5381859985, KRS 0000784131, REGON 383220124</p>
<p>Poniżej znajdziesz regulamin, w którym zawarte zostały informacje m.in. o sposobie złożenia zamówienia i zawarcia umowy, formach płatności dostępnych w sklepie, czy postępowaniu reklamacyjnym.</p>
<p>W razie jakichkolwiek pytań, jestem do Twojej dyspozycji pod adresem e-mail biuro@genfactor.pl</p>
<p>Pozdrawiam i życzę udanych zakupów</p>
<p>Wojciech Karwowski</p>
<p>§1 Definicje<br />
Na potrzeby niniejszego regulaminu, przyjmuje się następujące znaczenie poniższych pojęć:</p>
<p>1. Kupujący – osoba fizyczna, osoba prawna lub ułomna osoba prawna,</p>
<p>2. Konsument – osoba fizyczna zawierająca ze Sprzedawcą umowę niezwiązaną z bezpośrednio z jej działalnością gospodarczą lub zawodową,</p>
<p>3. Regulamin – niniejszy regulamin, dostępny pod adresem https://shop.genfactor.pl/terms,</p>
<p>4. Sklep – sklep internetowy dostępny pod adresem https://shop.genfactor.pl,</p>
<p>5. Sprzedawca – Saint Lazarus Sp. z o.o., ul. Pocztowa 4, 21-300 Radzyń Podlaski, NIP 5381859985, KRS 0000784131, REGON 383220124</p>

<p>§2 Postanowienia wstępne<br />
Za pośrednictwem Sklepu, Sprzedawca prowadzi sprzedaż treści cyfrowych, świadcząc jednocześnie na rzecz Kupującego usługi drogą elektroniczną zgodnie z § 3 Regulaminu.</p>
<p>Regulamin określa zasady i warunki korzystania ze Sklepu, a także prawa i obowiązki Sprzedawcy i Kupującego.</p>
<p>Do dokonania zakupu za pośrednictwem Sklepu nie jest konieczne spełnienie szczególnych warunków technicznych przez komputer lub inne urządzenie Kupującego. Wystarczające są:</p>
<p>1. dostęp do Internetu,</p>
<p>2. standardowy system operacyjny,</p>
<p>3. standardowa przeglądarka internetowa,</p>
<p>4. posiadanie aktywnego adresu e-mail.</p>
<p>Do korzystania z treści cyfrowych konieczne jest spełnienie następujących warunków technicznych przez komputer lub inne urządzenie Kupującego:</p>
<p>1. dostęp do Internetu,</p>
<p>2. standardowy system operacyjny,</p>
<p>3. standardowa przeglądarka internetowa,</p>
<p>4. standardowy pakiet biurowy (np. Microsoft Office, OpenOffice, LibreOffice),</p>
<p>5. standardowa przeglądarka plików .pdf (np. AdobeReader),</p>
<p>6. standardowy odtwarzacz plików wideo (np. WindowsMediaPlayer),</p>
<p>7. posiadanie aktywnego adresu e-mail.</p>
<p>W sytuacji, w której, korzystanie z treści cyfrowych wymagałoby spełnienia dodatkowych warunków technicznych, warunki te wskazane są w opisie treści cyfrowej w Sklepie.</p>
<p>Kupujący nie może dokonać zakupu anonimowo, ani pod pseudonimem.</p>
<p>Zakazane jest dostarczanie przez Kupującego treści o charakterze bezprawnym, w szczególności poprzez przesyłanie takich treści w ramach formularzy dostępnych w Sklepie.</p>
<p>Wszystkie ceny podane w Sklepie są cenami brutto.</p>
<p>§3 Usługi świadczone drogą elektroniczną<br />
Za pośrednictwem Sklepu, Sprzedawca świadczy na rzecz Kupującego usługi drogą elektroniczną.</p>
<p>Podstawową usługą świadczoną drogą elektroniczną na rzecz Kupującego przez Sprzedawcę jest umożliwienie Kupującemu złożenia zamówienia w Sklepie prowadzącego do zawarcia ze Sprzedawcą umowy. Złożenie zamówienia możliwe jest bez konieczności posiadania konta w Sklepie.</p>
<p>Jeżeli Kupujący zdecyduje się założyć konto w Sklepie, Sprzedawca świadczy również na rzecz Kupującego usługę drogą elektroniczną polegającą na założeniu i utrzymywaniu konta w Sklepie. W koncie przechowywane są dane Kupującego oraz historia złożonych przez niego zamówień w Sklepie. Kupujący loguje się do Konta z wykorzystaniem swojego adresu e-mail oraz zdefiniowanego przez siebie hasła.</p>
<p>Założenie konta w Sklepie odbywa się poprzez zaznaczenie stosownego checkboxa w procesie składania zamówienia lub wypełnienie samodzielnego formularza rejestracji konta dostępnego w Sklepie. Kupujący może w każdej chwili usunąć konto z poziomu panelu zarządzania kontem lub przesyłając stosowne żądanie do Sprzedawcy. Usunięcie konta nie spowoduje usunięcia informacji o złożonych zamówieniach z wykorzystaniem konta, które to informacje Sprzedawca będzie przechowywał do czasu upływu przedawnienia roszczeń z umowy zawartej za pośrednictwem Sklepu / przez cały czas funkcjonowania Sklepu, chyba że wcześniej Kupujący sprzeciwi się przechowywaniu tych informacji, a Sprzedawca nie będzie posiadał nadrzędnego interesu w ich przechowywaniu.</p>
<p>Jeżeli Kupujący zdecyduje się zapisać do newslettera, Sprzedawca świadczy również na rzecz Kupującego usługę drogą elektroniczną polegającą na przesyłaniu Kupującemu wiadomości e-mail zawierających informacje o nowościach, promocjach, produktach lub usługach Sprzedawcy. Zapis do newslettera odbywa się poprzez wypełnienie i przesłanie formularza zapisu do newslettera lub poprzez zaznaczenie stosownego checkboxa w procesie składania zamówienia. Kupujący może w każdej chwili zrezygnować z otrzymywania newslettera, klikając w przycisk służący do rezygnacji widoczny w każdej wiadomości przesyłanej w ramach newslettera lub przesyłając stosowne żądanie do Sprzedawcy.</p>
<p>Usługi świadczone są drogą elektroniczną na rzecz Kupującego nieodpłatnie. Odpłatne są natomiast umowy o dostarczenie treści cyfrowych zawierane za pośrednictwem Sklepu.</p>
<p>W celu zapewnienia bezpieczeństwa Kupującemu i przekazu danych w związku z korzystaniem ze Sklepu, Sprzedawca podejmuje środki techniczne i organizacyjne odpowiednie do stopnia zagrożenia bezpieczeństwa świadczonych usług, w szczególności środki służące zapobieganiu pozyskiwania i modyfikacji danych osobowych przez osoby nieuprawnione.</p>
<p>Sprzedawca podejmuje działania w celu zapewnienia w pełni poprawnego funkcjonowania Sklepu. Kupujący powinien poinformować Sprzedawcę o wszelkich nieprawidłowościach lub przerwach w funkcjonowaniu Sklepu.</p>
<p>Wszelkie reklamacje związane z funkcjonowaniem Sklepu, Kupujący może zgłaszać za pośrednictwem poczty elektronicznej na adres e-mail sklep@genfactor.pl. W reklamacji Kupujący powinien określić rodzaj i datę wystąpienia nieprawidłowości związanej z funkcjonowaniem Sklepu. Sprzedawca będzie rozpatrywać wszelkie reklamacje w terminie do 30 dni od otrzymania reklamacji i poinformuje Klienta o jej rozstrzygnięciu na adres e-mail składającego reklamację.</p>
<p>§4 Prawa własności intelektualnej<br />
Sprzedawca poucza niniejszym Kupującego, że treści dostępne na stronach Sklepu oraz treści cyfrowe sprzedawane za pośrednictwem Sklepu mogą stanowić utwory w rozumieniu ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych, do których prawa autorskie przysługują Sprzedawcy.</p>
<p>Sprzedawca poucza niniejszym Kupującego, że dalsze rozpowszechnianie treści objętych prawami autorskimi przez Kupującego bez zgody Sprzedawcy, za wyjątkiem korzystania z treści w ramach dozwolonego użytku osobistego, stanowi naruszenie praw autorskich przysługujących Sprzedawcy i może skutkować odpowiedzialnością cywilną lub karną.</p>
<p>Materiały użyte do prezentacji produktów, tj.: zdjęcia, opisy i inne są częścią Sklepu, stanowią własność jego Sprzedawcy i chronione są prawami autorskimi. Zabrania się ich używania w celach handlowych lub prezentacji w jakichkolwiek mediach bez zgody Sprzedawcy.</p>
<p>§5 Zawieranie umowy<br />
Kupujący może złożyć zamówienie jako zarejestrowany klient albo jako gość.</p>
<p>Zarejestrowanym klientem jest Kupujący, który posiada konto w Sklepie. Kupujący może założyć konto poprzez zaznaczenie stosownego checkboxa w procesie składania zamówienia lub wypełnienie samodzielnego formularza rejestracji konta dostępnego w Sklepie.</p>
<p>Jeżeli Kupujący posiada konto w Sklepie, przed złożeniem zamówienia powinien się do niego zalogować. Logowanie możliwe jest również w trakcie składania zamówienia poprzez kliknięcie w link dostępny w ramach wyświetlanego komunikatu.</p>
<p>Złożenie zamówienia odbywa się poprzez wypełnienie formularza zamówienia po uprzednim dodaniu do koszyka interesujących Kupującego treści cyfrowych. W formularzu konieczne jest podanie danych niezbędnych do realizacji zamówienia. Warunkiem złożenia zamówienia jest akceptacja Regulaminu, z którym Kupujący powinien uprzednio się zapoznać. W razie jakichkolwiek wątpliwości dotyczących Regulaminu, Kupujący może skontaktować się ze Sprzedawcą.</p>
<p>Proces składania zamówienia kończy kliknięcie w przycisk finalizujący zamówienie. Kliknięcie w przycisk finalizujący zamówienie stanowi oświadczenie woli Kupującego prowadzące do zawarcia ze Sprzedawcą umowy o dostarczenie treści cyfrowych,</p>
<p>Jeżeli Kupujący wybrał przy składaniu zamówienia płatność on-line, po kliknięciu w przycisk finalizujący zamówienie zostanie przekierowany do bramki płatności obsługiwanej przez zewnętrznego operatora płatności celem dokonania płatności za zamówienie. Jeżeli Kupujący wybrał płatność przelewem bankowym, po kliknięciu w przycisk finalizujący zamówienie zostanie przekierowany na stronę Sklepu z potwierdzeniem zamówienia oraz instrukcją dokonania płatności. Zapłata za zamówienie powinna nastąpić w ciągu jednego dnia od zawarcia umowy.</p>
<p>§6 Płatność<br />
Jedyną dostępną opcją płatności w Sklepie jest płatność elektroniczna.</p>
<p>Kupujący może dokonać płatności za zakupione produkty w następujący sposób:</p>
<p>przelew bankowy<br />
blik<br />
karty płatnicze<br />
Płatności elektroniczne, w tym płatności kartą płatniczą, obsługiwane są przez PayU S.A. NIP: 779-23-08-495 ul.Grunwaldzka 186, 60-166 Warszawa</p>
<p>Jeżeli Kupujący poprosił o wystawienie faktury, zostanie ona doręczona Kupującemu drogą elektroniczną, na adres e-mail podany w formularzu zamówienia.</p>
<p>Płatność jednorazowa pobierana jest za zakup jednego produktu ze sklepu</p>
<p>Kupujący zawierając umowę z Fundacja Św. Łazarza, oraz wybierając określony abonament wyraża zgodę na cykliczne, co miesięczne pobieranie przez Operatora Płatności (PayU S.A) z karty płatniczej kwoty pieniężnej odpowiadającej wysokości wartości opłaty za abonament. Opłata będzie pobierana przez Operatora płatności raz w miesiącu lub rocznie.</p>
<p>Kupujący w ramach usługi płatności cyklicznych ma możliwość zapisania danych karty i zlecenia stałego polecania zapłaty. Dane karty będą przechowywane przez Operatora Płatności (PayU S.A.). PayU pośrednicząc w dokonaniu płatności udostępnia narzędzie Token (wirtualnych identyfikatorów karty), umożliwiające przypisanie do indywidualnego Klienta unikalnego identyfikatora za pomocą którego Klient cyklicznie dokonuje płatności na rzecz Fundacji Św. Łazarza.</p>
<p>§7 Wykonanie umowy<br />
Realizacja zamówienia obejmującego treści cyfrowe następuje poprzez przesłanie na adres e-mail podany przez Kupującego w formularzu zamówienia wiadomości zawierającej instrukcję pobrania lub uzyskania dostępu do zakupionych treści cyfrowych.</p>
<p>W przypadku kursów on-line, dla Kupującego może zostać utworzone konto w ramach platformy kursowej, a dane dostępowe zostaną przesłane w ramach wiadomości e-mail, o której mowa w ust. 1 powyżej.</p>
<p>W przypadku kursów on-line, dostęp do treści wchodzących w skład kursu może być ograniczony czasowo, zgodnie z informacjami zawartymi w opisie kursu na stronie Sklepu. W takiej sytuacji, po upływie wskazanego okresu czasu, Kupujący utraci dostęp do kursu.</p>
<p>W przypadku kursów on-line, Kupujący zobowiązany jest korzystać z platformy kursowej w sposób zgodny z prawem, Regulaminem i dobrymi obyczajami, a w szczególności:</p>
<p>1. korzystać z platformy w sposób niezakłócający korzystanie z platformy przez pozostałych jej użytkowników, nienaruszający jakichkolwiek praw, dóbr lub interesów osób trzecich, niewpływający negatywnie na funkcjonowanie platformy, szczególnie poprzez wykorzystanie złośliwego oprogramowania,</p>
<p>2. nie udostępniać danych dostępowych do swojego konta w platformie jakimkolwiek osobom trzecim,</p>
<p>3. nie rozpowszechniać kursu ani jego poszczególnych fragmentów bez uprzedniej zgody Sprzedawcy.</p>
<p>W razie korzystania z platformy kursowej w sposób sprzeczny z ust. 4 powyżej, Sprzedawca zachowuje prawo do zablokowania Kupującemu dostępu do kursu.</p>
<p>§8 Odstąpienie Konsumenta od umowy<br />
Począwszy od 01.04.2020 r., prawo do odstąpienia od umowy na zasadach opisanych w niniejszym paragrafie oraz wynikających z ustawy o prawach konsumenta przysługuje również osobie fizycznej zawierającej ze Sprzedawcą umowę bezpośrednio związaną z jej działalnością gospodarczą, gdy z treści tej umowy wynika, że nie posiada ona dla tej osoby charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej. W związku z tym, gdy w ramach niniejszego paragrafu mowa jest o uprawnieniach Konsumenta, począwszy od 01.04.2020 r. uprawnienia te dotyczą również osoby spełniającej powyższe kryteria.</p>
<p>Prawo odstąpienia od umowy zawartej na odległość nie przysługuje konsumentowi w odniesieniu do umów o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą konsumenta przed upływem terminu do odstąpienia od umowy i po poinformowaniu go przez przedsiębiorcę o utracie prawa odstąpienia od umowy.</p>
<p>Aby odstąpić od umowy, Konsument musi poinformować Sprzedawcę o swojej decyzji o odstąpieniu od umowy w drodze jednoznacznego oświadczenia – na przykład pismo wysłane pocztą, faksem lub pocztą elektroniczną.</p>
<p>Konsument może skorzystać z wzoru formularza odstąpienia od umowy, dostępnego pod adresem https://genfactor.pl/doc/rozwiazanie.pdf jednak nie jest to obowiązkowe.</p>
<p>Aby zachować termin do odstąpienia od umowy, wystarczy, aby Konsument wysłał informację dotyczącą wykonania przysługującego Konsumentowi prawa odstąpienia od umowy przed upływem terminu do odstąpienia od umowy.</p>
<p>W przypadku odstąpienia od umowy, Sprzedawca zwraca Konsumentowi wszystkie otrzymane od Konsumenta płatności niezwłocznie, nie później niż 14 dni od dnia, w którym Sprzedawca został poinformowany o wykonaniu prawa odstąpienia od umowy.</p>
<p>§9 Odpowiedzialność za wady<br />
Sprzedawca ma obowiązek dostarczyć Kupującemu treść cyfrową wolną od wad.</p>
<p>Sprzedawca jest odpowiedzialny względem Kupującego, jeżeli treść cyfrowa ma wadę fizyczną lub prawną (rękojmia za wady).</p>
<p>Jeżeli Kupujący stwierdzi wadę treści cyfrowej, powinien poinformować o tym Sprzedawcę, określając jednocześnie swoje roszczenie związane ze stwierdzoną wadą lub składając oświadczenie stosownej treści.</p>
<p>Kupujący może skorzystać z formularza reklamacyjnego, dostępnego pod adresem https://genfactor.pl/doc/rek.pdf, jednak nie jest to obowiązkowe.</p>
<p>Kupujący może kontaktować się ze Sprzedawcą za pośrednictwem poczty elektronicznej.</p>
<p>Sprzedawca ustosunkuje się do składanej przez Kupującego reklamacji w ciągu 14 dni od dnia doręczenia mu reklamacji za pomocą takiego środka komunikacji, przy wykorzystaniu którego reklamacja została złożona.</p>
<p>Począwszy od 01.06.2020 r., przepisy w zakresie rękojmi Sprzedawcy za wady rzeczy sprzedanej dotyczące Konsumentów, znajdują zastosowanie również do osoby fizycznej zawierającej ze Sprzedawcą umowę bezpośrednio związaną z jej działalnością gospodarczą, gdy z treści tej umowy wynika, że nie posiada ona dla tej osoby charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej.</p>
<p>§10 Dane osobowe i pliki cookies<br />
Administratorem danych osobowych Kupującego jest Sprzedawca.</p>
<p>Dane osobowe Kupującego przetwarzane są w następujących celach i w oparciu o następujące podstawy prawne:</p>
<p>1. zawarcie i wykonanie umowy – art. 6 ust. 1 lit. b RODO,</p>
<p>2. realizacja obowiązków podatkowo-księgowych – art. 6 ust. 1 lit. c RODO,</p>
<p>3. obrona, dochodzenia lub ustalenie roszczeń związanych z umową, co jest prawnie uzasadnionym interesem realizowanym przez Sprzedawcę – art. 6 ust. 1 lit. f RODO,</p>
<p>4. identyfikacja klienta powracającego, co jest prawnie uzasadnionym interesem realizowanym przez Sprzedawcę – art. 6 ust. 1 lit. f RODO,</p>
<p>5. obsługa zapytań kierowanych przez Kupujących nieprowadzących jeszcze do zawarcia umowy, co jest prawnie uzasadnionym interesem realizowanym przez Sprzedawcę – art. 6 ust. 1 lit. f RODO,</p>
<p>6. wysyłka newslettera, po uprzednio wyrażonej zgodzie – art. 6 ust. 1 lit. a RODO.</p>
<p>Odbiorcami danych osobowych Kupującego są: urzędy skarbowe, biuro rachunkowe, kancelaria prawna, hostingodawca, dostawca systemu do fakturowania, dostawca systemu CRM, dostawca systemu mailingowego.</p>
<p>Z uwagi na korzystanie z systemu mailingowego Active Campagne, dane osobowe Kupujących, którzy zapisali się do newslettera, przekazywane są do Stanów Zjednoczonych Ameryki (USA) w związku z ich przechowywaniem na serwerach zlokalizowanych w USA. Dostawca systemu Active Campagne gwarantuje odpowiedni poziom ochrony danych osobowych poprzez odpowiednie mechanizmy zgodności (Tarcza Prywatności).</p>
<p>Dane osobowe Kupującego są przechowywane w bazie Sprzedawcy przez cały czas prowadzenia działalności gospodarczej w celu zapewnienia sobie możliwości identyfikacji klienta powracającego, czemu jednak Kupujący może się sprzeciwić, domagając się usunięcia swoich danych z bazy Sprzedawcy. Jeżeli sprzeciw taki zostanie złożony przed upływem terminu przedawnienia roszczeń z zawartej umowy, Sprzedawca będzie miał nadrzędny interes w przechowywaniu danych Kupującego do czasu upływu terminu przedawnienia roszczeń. Dokumentacja księgowa zawierające dane osobowe Kupującego jest przechowywana przez okres wymagany przez przepisy prawa.</p>
<p>Uprawnienia Kupującego związane z przetwarzaniem danych osobowych: prawo do żądania od Sprzedawcy dostępu do danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo wniesienia sprzeciwu wobec przetwarzania, prawo do przenoszenia danych, prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.</p>
<p>Podanie danych osobowych przez Kupującego jest dobrowolne, ale niezbędne by skontaktować się ze Sprzedawcą, założyć konto użytkownika, zawrzeć umowę lub zapisać się do newslettera.</p>
<p>Sklep wykorzystuje technologię plików cookies.</p>
<p>Szczegóły związane z danymi osobowymi oraz plikami cookies opisane zostały w polityce prywatności dostępnej pod adresem https://shop.genfactor.pl/cookies</p>
<p>Z uwagi na możliwość zakupu abonamentu, dane karty Kupującego są przechowywane przez PayU S.A.</p>
<p>§11 Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń<br />
Sprzedawca wyraża zgodę na poddanie ewentualnych sporów wynikłych w związku z zawartymi umowami o dostarczenie treści cyfrowych na drodze postępowania mediacyjnego. Szczegóły zostaną określone przez strony konfliktu.</p>
<p>Konsument ma możliwość skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń. Między innymi, Konsument ma możliwość:</p>
<p>1. zwrócenia się do stałego polubownego sądu konsumenckiego z wnioskiem o rozstrzygnięcie sporu wynikłego z zawartej umowy,</p>
<p>2. zwrócenia się do wojewódzkiego inspektora Inspekcji Handlowej z wnioskiem o wszczęcie postępowania mediacyjnego w sprawie polubownego zakończenia sporu między Kupującym a Sprzedawcą,</p>
<p>3. skorzystania z pomocy powiatowego (miejskiego) rzecznika praw konsumenta lub organizacji społecznej, do której statutowych zadań należy ochrona konsumentów.</p>
<p>Bardziej szczegółowych informacji na temat pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń, Konsument może szukać na stronie internetowej http://polubowne.uokik.gov.pl.</p>
<p>Konsument może również skorzystać z platformy ODR, która dostępna jest pod adresem http://ec.europa.eu/consumers/odr. Platforma służy rozstrzyganiu sporów pomiędzy konsumentami i przedsiębiorcami dążącymi do pozasądowego rozstrzygnięcia sporu dotyczącego zobowiązań umownych wynikających z internetowej umowy sprzedaży lub umowy o świadczenie usług.</p>
<p>§12 Postanowienia końcowe<br />
Sprzedawca zastrzega sobie prawo do wprowadzania oraz odwoływania ofert, promocji oraz do zmiany cen produktów w Sklepie bez uszczerbku dla praw nabytych przez Kupującego, w tym w szczególności warunków umów zawartych przed dokonaniem zmiany.</p>
<p>Sprzedawca zastrzega sobie możliwość wprowadzania zmian w Regulaminie. Do umów zawartych przed zmianą Regulaminu stosuje się Regulamin obowiązujący w dacie zawarcia umowy.</p>
<p>Wszelkie spory związane z umowami zawieranymi za pośrednictwem Sklepu będą rozpatrywane przez polski sąd powszechny właściwy ze względu na miejsce stałego wykonywania działalności gospodarczej przez Sprzedawcę. Postanowienie to nie znajduje zastosowania do Konsumentów, w przypadku których właściwość sądu rozpatrywana jest na zasadach ogólnych. Począwszy od 01.06.2020 r. postanowienie to nie znajduje również zastosowania do osoby fizycznej zawierającej ze Sprzedawcą umowę bezpośrednio związaną z jej działalnością gospodarczą, gdy z treści tej umowy wynika, że nie posiada ona dla tej osoby charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej – w przypadku takiej osoby właściwość sądu rozpatrywana jest na zasadach ogólnych.</p>
<p>Niniejszy Regulamin obowiązuje od dnia 01.04.2020 r.</p>

    </div>
  )
}
