
export default function FormInputTax({ controls, register, onChange}) {

  const { name, label, type } = controls
 
  return (
    <div className="form__input">
      <label htmlFor={name}>{label}</label>
      <input {...register(name)} id={name} type={type} onChange={(e) => onChange(e.target.value)}/>
    </div>
  )
}
