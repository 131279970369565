import { useState, useEffect } from 'react';
import { motion } from 'framer-motion'

import { useGetProducts } from '../hooks/useGetProducts';
import { useCart } from '../controllers/Cart'
import { useUser } from '../controllers/UserContext';
import { useAuth } from "../controllers/useAuth"

import ProductItem from './ProductItem'
import ListSkeleton from './Skeletons/ListSkeleton';
import ErrorDisplay from './Layout/ErrorDisplay';


function List() {


  const categories = ['Gen Factor PH', 'Gen Factor', 'Personal Care', 'Retinoid']

  const { prods, err } = useGetProducts(categories);

  const [ products, setProducts ] = useState([]);
  const [ isPennding, setIsPennding ] = useState(true)

  const userCart = useCart()

  const userLoggedIn = useAuth()

  const userObject = useUser()

  useEffect(() => {
    let outputState = prods.sort((x, y) => {
      return x.seq - y.seq
    })
    setProducts(outputState)
    if (products.length !== 0) {
      setIsPennding(false)
    }
  }, [prods, products]);

  const variants = {
    active: {
        scale: .95,
        rotate: 0,
        transition: {
            duration: 0.9,
            type: "spring", 
            stiffness: 100
        }
    }
  }

  if (err) {
    console.log(err)
  }

  return (
    <>
      {err && <ErrorDisplay />}
      <div className="list">
        {isPennding ? <ListSkeleton /> : 
          products.map((product) => {
            if (product.stock > 0) {
              const productInCart = userCart.find((item) => {
                return item.productId === product.id
              })
              return (
              <motion.div className='list__card' key={product.id} whileHover='active' variants={variants}>
                <ProductItem product={product} productInCart={productInCart} userLoggedIn={userLoggedIn} type={userObject.user.userType}/>
              </motion.div>)
            } else {
              return null
            }
          }
        )}
      </div></>
  );

}

export default List;
