import React from 'react'

export default function Payments() {
  return (
    <div className='payments__page'>
      <h2 className='marginTop2'>Metody płatności</h2>
<p>W naszym sklepie mamy do dyspozycji kilka metod płatności. Poniżej opisujemy je krótko, aby pomóc w wybraniu tej najbardziej odpowiedniej dla Ciebie.</p>
<p>Przelew błyskawiczny &#8211; w tym przypadku w jednym z kroków dokonywania zamówienia będzie przekierowanie na stronę operatora płatności, gdzie będzie możliwość wyboru Twojego banku, a następnie bezpieczne logowanie i potwierdzenie płatności za zamówienie.</p>
<p>Karta platnicza &#8211; w tym przypadku na stronie operatora płatności będzie miejsce na podanie numeru karty i dokonaniu niezbędnej autoryzacji, np kod CVV.</p>
<p>Blik &#8211; również na stronie operatora płatności będzie możliwość podania kodu Blik z aplikacji bankowej.</p>
<p>Płatności przelewem błyskawicznym, kartą płatniczą lub Blikiem są płatnościami błyskawicznymi, w ciągu kilku minut otrzymujemy od operatora płatności informację o jej realizacji i przechodzimy do dalszych części obsługi Twojego zamówienia. Płatności te są u nas obsługiwane przez PayU.</p>
		
    </div>
  )
}
