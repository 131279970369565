import React, { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";
// import { useParams } from "react-router";

import { GetData, usePostData } from "../../controllers/fetchData.jsx";

import { filterDateAction } from "../../controllers/Filtering.jsx";

import AdminReferralUserParamValue from "./AdminReferralUserParamValue.jsx";
import DatePicker from "../DatePicker.jsx";

export default function ReferralUser({ referral_user_email }) {
  const { data, pennding, error } = GetData(`ref-users/${referral_user_email}`);

  const { response, idle, postError, makePostRequest } = usePostData();

  const [user, setUser] = useState();
  const [isPennding, setIsPennding] = useState(true);
  const [err, setErr] = useState(null);
  const [paramName, setParamName] = useState();
  const [ordersValue, setOrdersValue] = useState();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    setUser(data);
    if (data) {
      setOrders(data.referral_user_orders);
    }
    setIsPennding(pennding);
    setErr(error);
  }, [data, pennding, error]);

  const addParam = () => {
    const newParam = {
      referral_user_email: referral_user_email,
      referral_user_param_name: paramName,
    };
    makePostRequest("ref-users/add-param", newParam);
  };

  const disableParam = (param) => {
    const disableParam = {
      referral_user_param: param,
    };
    makePostRequest("ref-users/disable-param", disableParam);
  };

  useEffect(() => {
    let value = 0;
    orders.forEach((order) => {
      if (order.paymentStatus === "COMPLETED") value = value + order.orderValue;
    });
    setOrdersValue(value);
  }, [orders]);

  const filterAction = () => {
    if (data && orders) {
      const ordersByDate = filterDateAction(data.referral_user_orders, fromDate, toDate);
      setOrders(ordersByDate);
    }
  };

  return (
    <div className="mt-6">
      {isPennding ? <div>Ładowanie...</div> : <></>}
      {err ? <div>{err}</div> : <></>}
      {user ? (
        <div>
          <p>
            Twoja skuteczność jako Ambasadora <strong className="text-tl-primary">Gen Factor</strong>
          </p>
          <div className="mt-10">
            <div className="mb-5">Wybierz zakres dat:</div>
            <DatePicker
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              filterAction={filterAction}
            />
          </div>
          <div className="mt-10">
            <p className="">Wartość zamówień:</p>
            <h3>{ordersValue} zł</h3>
          </div>
          <div className="mt-10">
            <p>Linki referencyjne do zapisu klientów:</p>
            {user.referral_user_params.map((param) => (
              <div
                className=""
                key={param.referral_user_param_name}
              >
                <h3 className="mb-0">{param.referral_user_param_name}:</h3>
                <h3 className="mt-0 mb-0">https://shop.genfactor.pl/rejestracja?ref=true&param={param.referral_user_param}</h3>
                <p className="mt-0 mb-0">
                  Wartość zamówień dla tego parametru:{" "}
                  <span className="text-cyan-500 font-bold">
                    <AdminReferralUserParamValue
                      orders={orders}
                      param={param.referral_user_param}
                    />
                  </span>
                </p>
                {param.referral_user_param_state === "active" ? (
                  <div
                    className="mb-10 mt-1 font-bold text-tl-primary hover:text-tl-secondary hover:cursor-pointer"
                    onClick={() => disableParam(param.referral_user_param)}
                  >
                    Wyłącz link
                  </div>
                ) : (
                  <div className="text-tl-accent">Link wyłączony</div>
                )}
              </div>
            ))}
          </div>
          <hr className="mt-16" />
          <div className="mt-15 mb-10">
            <p>Tutaj możesz wygenerować nowy parametr, dzięki czemu możesz mierzyć skuteczność różnych kanałów pozyskiwania klientów. Nazwy parametrów są widoczne tylko dla Ciebie.</p>
            <div className="flex gap-3 items-center">
              <p className="mb-0">Nazwa parametru: </p>
              <input
                type=""
                onChange={(e) => setParamName(e.target.value)}
                className=""
              />
              <button
                className="bg-tl-primary px-6 py-1 text-tl-light hover:bg-tl-secondary w-52"
                onClick={() => addParam()}
                disabled={idle}
              >
                Dodaj Parametr
              </button>
            </div>
            {response ? <p>{response.data}</p> : <></>}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
