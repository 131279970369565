
import Icons from "./Icons";
import Logo from "./Logo";
import Navigation from "./Navigation";
import LanguageSwitcher from "../LanguageSwitcher";

import { FaBars } from "react-icons/fa";

export default function Header({ handleModal }) {

  return (
    <>
      <div className="header_box">
        <div className="header_out">
          <Logo />
          <div className="header">
            <Navigation location={'desktop'}/>
            <Icons location={'desktop'}/>
            <div onClick={() => handleModal()} className="mobile"><FaBars /></div>
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </>
  )
}
