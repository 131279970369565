import { NavLink } from "react-router-dom"
import { useSearchParams } from 'react-router-dom'

import LogoDark from "../../components/Layout/LogoDark";
import ResetFormAfter from "../../components/ResetFormAfter";

export default function ResetFormPage() {

  const [ params ] = useSearchParams()
  const token = (params.get('token'))
  

  return (
    <div className="login__page">
      <div className="form__card mt2">
        <LogoDark />
        <br /> 
        <ResetFormAfter token={token} />
        <br />
        <p>Nie masz jeszcze konta?</p>
        <p><NavLink to='/rejestracja'>Zarejestruj się za darmo!</NavLink></p>
        <br />
        <NavLink to='/'><button className="btn">Wróć do sklepu</button></NavLink>
      </div>
    </div>
  )
}
