import React from "react";

export default function Logo() {
  return (
    <div className="logo">
      <img
        src="../assets/logo_genfactor.png"
        alt="logo"
      />
    </div>
  );
}
