import { useContext, useReducer, createContext, useEffect } from "react";

const LanguageStateContext = createContext()
const LanguageDispatchContext = createContext()

const reducer = (state, action) => {
  switch(action.type) {
    case "pl":
      console.log(action.type)
      return 'pl'
    case "gb":
      console.log(action.type)
      return 'gb'
    case "us":
      console.log(action.type)
      return 'us'
    case "eu":
      console.log(action.type)
      return 'eu'
    case "READ":
      return state
    default:
      throw new Error(`unknown action ${action.language}`)
  }
}

export function LanguageProvider ({ children }) {

  const [ state, dispatch ] = useReducer(reducer, ['pl']);
  // const { billingAdress, isPennding, err } = useGetData(`/api/billing/${state.user.id}`)

  useEffect(() => {
    dispatch({ type: "READ" })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LanguageDispatchContext.Provider value={dispatch}>
       <LanguageStateContext.Provider value={state}>
        {children}
      </LanguageStateContext.Provider>
    </LanguageDispatchContext.Provider>
  )
  
}

export function useLanguage() {
  return useContext(LanguageStateContext)
}

export function useDispatchLanguage() {
  return useContext(LanguageDispatchContext)
}