
import { FiPlusCircle } from "react-icons/fi";
import { FiMinusCircle } from "react-icons/fi";

export default function Quantity({ handleQuantityChange, children }) {

  return (
    <div className="quantity">
      <button className="flex justify-center w-8">
        {children === 1 ? <FiMinusCircle className="quantity__icon w-8 min-w-8"/> : <FiMinusCircle className="quantity__icon w-8 min-w-8" onClick={() => handleQuantityChange('minus')}/>}
      </button>
      <div className="quantity__value">{children}</div>
      <button className="flex justify-center w-8"><FiPlusCircle className="quantity__icon w-8 min-w-8" onClick={() => handleQuantityChange('plus')}/></button>
    </div>
  )
}
