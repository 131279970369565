import { useState, useEffect } from "react";

export default function MyAccountData(user) {
  const [userData, setUserData] = useState({});
  const [userComplete, setUserComplete] = useState(false);

  const pointName = null;

  useEffect(() => {
    if (user && user.user && user.user.billingAddress && user.user.billingAddress.name) {
      const d = user.user.shippingAddress;
      const data = {
        ...user.user.billingAddress,
        email: user.user.email,
        repeatEmail: user.user.email,
        shippingName: d.name,
        shippingSurname: d.surname,
        shippingCopanyName: d.companyName,
        shippingStreet: d.street,
        shippingAddressNumber1: d.addressNumber1,
        shippingAddressNumber2: d.addressNumber2,
        shippingZipCode: d.zipCode,
        shippingCity: d.city,
        shippingCountry: d.country,
        shippingPhone: d.phone,
        pointName: pointName,
      };
      setUserData(data);
      setUserComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="myAccount__data container text-center">
      {userComplete ? (
        <div className="flex gap-10">
          <div className="flex flex-col grow">
            <h4 className="mb-5 mt-2 font-bold text-xl">Dane rozliczeniowe</h4>
            <p>
              {userData.name} {userData.surname}
            </p>
            <p>{userData.companyName}</p>
            <p>
              NIP/TAX: {userData.country} {userData.taxNumber}
            </p>
            <p>
              {userData.street} {userData.addressNumber1} {userData.addressNumber2 ? `/ ${userData.addressNumber2}` : ""}
            </p>
            <p>
              {userData.zipCode} {userData.city}, {userData.country}
            </p>
            <p>{userData.phone}</p>
          </div>
          <div className="flex flex-col grow">
            <h4 className="mb-5 mt-2 font-bold text-xl">Dane dostawy</h4>
            <p>
              {userData.shippingName} {userData.shippingSurname}
            </p>
            {userData.shippingCopanyName ? <p>userData.shippingCopanyName</p> : <></>}
            <p>
              {userData.shippingStreet} {userData.shippingAddressNumber1} {userData.shippingAddressNumber2 ? `/ ${userData.shippingAddressNumber2}` : ""}
            </p>
            <p>
              {userData.shippingZipCode} {userData.shippingCity}, {userData.shippingCountry}
            </p>
            <p>{userData.shippingPhone}</p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
