import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
// import { useNavigate } from "react-router"
// import { NavLink } from "react-router-dom"
import Axios from "axios"

import { useForm } from 'react-hook-form'

import { ticketShema } from '../../controllers/ValidationShemas'

import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormTextarea from '../../components/FormTextarea'

import { apiUrl } from '../../Vars'


export default function Help() {

  const [ errMsg, setErrMsg ] = useState(null)
  const [ ticketId, setTicketId ] = useState(null)

  const { register, handleSubmit, formState } = useForm({ resolver: zodResolver(ticketShema)});

  const { errors } = formState

  const pages = [{name:'Strona logowania', value:'login'}, {name: 'Strona rejestracji', value:'register'}, {value: 'product', name: 'Strona produktu'}, {value: 'cart', name: 'Strona koszyka'}, {value: 'order', name: 'Strona zamówienia'}, {value: 'payment', name: 'Strona płatności'}]

  const email = {name: 'email', label: 'Adres email', options: ''}
  const phone = {name: 'phone', label: 'Numer telefonu', options: ''}
  const description = {name: 'description', label: 'Opis problemu', options: 'text-area'}
  const page = {name: 'page', label: 'Strona, na której występuje problem', options: pages}

  const submitTicket = (data) => {
    console.log(data)
    Axios.post(`${apiUrl}/api/help-ticket`, {
      data
    }).then((response) => {
      console.log(response)
      setTicketId(response.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  const showError = (errors) => {
    console.log(errors)
  }

  return (
    <div className="help-page">
      <h2 className='mt2'>Pomoc techniczna</h2>
      {ticketId ? 
      <p>Przyjeliśmy Twoje zgłoszenie, jego numer to: <strong>{ticketId}</strong></p> : 
      <><p>Jeśli napotkałeś / napotkałaś jakiś problem techniczny w trakcie korzystania z naszego sklepu i w sekcji FAQ nie było porady do jego rozwiązania, możesz skorzystać z formularza poniżej, aby bespośrednio napisać do działu technicznego, który w miarę możliwości pomoże. Podanie danych kotaktowych jest niezbędne w celu dostarczenia rozwiązania problemu. Ważne jest to, aby zgłoszenie jak najbardziej szczegółowo opisywało zaistniały problem. Nawet z pozoru błache informacje mogą pomóc w identyfikacji problemu, co przyspieszy prace nad jego rozwiązaniem.</p>
      {errMsg !== null ? <p style={{ color: 'red' }}>{errMsg}</p> : <></>}
      <form action="" className='help-form' onSubmit={handleSubmit(submitTicket, showError)}>
        <FormInput controls={email} register={register}/>
        <span className="input__err">{errors.email?.message}</span>
        <FormInput controls={phone} register={register}/>
        <span className="input__err">{errors.email?.message}</span>
        <FormSelect controls={page} register={register} countryChange={console.log}/>
        <span className="input__err">{errors.country?.message}</span>
        <FormTextarea controls={description} register={register} />
        <span className="input__err">{errors.email?.message}</span>
        <p>*Wysyłając zgłoszenie wyrażasz zgodę na kontakt działu pomocy za pośrednictwem podanych danych.</p>
        <button className='btn' type="submit">Wyślij zgłoszenie</button>
      </form></>}
    </div>
  )
}
