

export default function Cookies() {
  return (
    <div className='cookies__page'>
      <h2 className="marginTop2">Polityka Prywatności i plików cookies</h2>
      <p>Dzień dobry!<br />
Jeżeli tutaj trafiłeś, to niezawodny znak, że cenisz swoją prywatność. Doskonale to rozumiemy, dlatego oddajemy w Twoje ręce dokument, w którym w jednym miejscu znajdziesz zasady przetwarzania danych osobowych oraz wykorzystywania plików cookies i innych technologii śledzących w związku z funkcjonowaniem strony https://genfactor.pl</p>
<p>Informacja formalna na początek – administratorem strony jest Saint Lazarus Sp. z o.o., ul. Pocztowa 4, 21-300 Radzyń Podlaski, NIP 5381859985, KRS 0000784131, REGON 383220124</p>
<p>Niniejsza polityka prywatności została skonstruowana w formie pytań i odpowiedzi. Wybór takiej formy został podyktowany dbałością o przejrzystość i czytelność prezentowanych Ci informacji. Poniżej znajdziesz spis treści niniejszej polityki odpowiadający pytaniom, na które kolejno odpowiadamy.</p>
<p># 1: Kto jest administratorem Twoich danych osobowych?</p>
<p># 2: Z kim możesz kontaktować się w sprawie przetwarzania Twoich danych osobowych?</p>
<p># 3: Jakie informacje na Twój temat posiadamy?</p>
<p># 4: Skąd mamy Twoje dane osobowe?</p>
<p># 5: Czy Twoje dane są bezpieczne?</p>
<p># 6: W jakich celach przetwarzamy Twoje dane osobowe?</p>
<p>Konto użytkownika – szczegóły</p>
<p>Zamówienia – szczegóły</p>
<p>Reklamacje i odstąpienia od umowy – szczegóły</p>
<p>Newsletter – szczegóły</p>
<p>Komentarze i opinie o produktach – szczegóły</p>
<p>Obsługa korespondencji – szczegóły</p>
<p>Obowiązki podatkowe i księgowe – szczegóły</p>
<p>Archiwum – szczegóły</p>
<p>Marketing własny – szczegóły</p>
<p>Analiza, statystyka, optymalizacja – szczegóły</p>
<p># 7: Jak długo będziemy przechowywać Twoje dane osobowe?</p>
<p># 8: Kim są odbiorcy Twoich danych osobowych?</p>
<p># 9: Czy przekazujemy Twoje dane do państw trzecich lub organizacji międzynarodowych?</p>
<p># 10: Czy korzystamy z profilowania? Czy podejmujemy w sposób zautomatyzowany decyzja na podstawie Twoich danych osobowych?</p>
<p># 11: Jakie uprawnienia przysługują Ci w związku z przetwarzaniem Twoich danych osobowych?</p>
<p># 12: Czy korzystamy z cookies i czym one właściwie są?</p>
<p># 13: Na jakiej podstawie korzystamy z plików cookies?</p>
<p># 14: Czy możesz wyłączyć pliki cookies?</p>
<p># 15: W jakich celach korzystamy z plików cookies własnych?</p>
<p># 16: Jakie pliki cookies podmiotów trzecich są wykorzystywane?</p>
<p>Google Analytics – szczegóły</p>
<p>Google Tag Manager – szczegóły</p>
<p>Google AdWords – szczegóły</p>
<p>Google AdSense – szczegóły</p>
<p>You Tube – szczegóły</p>
<p>Vimeo – szczegóły</p>
<p># 17: Czy śledzimy Twoje zachowania podejmowane w ramach naszej strony?</p>
<p># 18: Czy kierujemy do Ciebie targetowane reklamy?</p>
<p># 19: W jaki sposób możesz zarządzać swoją prywatnością?</p>
<p># 20: Czym są logi serwera?</p>
<p># 21: Czy jest jeszcze coś, o czym powinieneś wiedzieć?</p>
<p>W razie jakichkolwiek wątpliwości związanych z polityką prywatności, w każdej chwili możesz skontaktować się z nami, wysyłając wiadomość na adres biuro@genfactor.pl</p>
<p>#1: Kto jest administratorem Twoich danych osobowych?<br />
Administratorem Twoich danych osobowych jest Fundacja Św. Łazarza, ul. Pocztowa 4, 21-300 Radzyń Podlaski, Polska, NIP 1132898398, KRS 0000586649, REGON 36302342200000</p>
<p># 2: Z kim możesz kontaktować się w sprawie przetwarzania Twoich danych osobowych?<br />
W ramach wdrożenia ochrony danych osobowych w naszej organizacji, podjęliśmy decyzję o niewyznaczaniu inspektora ochrony danych osobowych z uwagi na fakt, że w naszej sytuacji nie jest to obowiązkowe. W sprawach związanych z ochroną danych osobowych oraz szeroko pojętą prywatnością możesz kontaktować się pod adresem e-mail biuro@genfactor.pl</p>
<p># 3: Jakie informacje na Twój temat posiadamy?<br />
W zależności od celu, możemy przetwarzać następujące informacje na Twój temat:</p>
<p>imię i nazwisko,</p>
<p>adres zamieszkania,</p>
<p>adres wykonywania działalności gospodarczej,</p>
<p>numer NIP,</p>
<p>adres e-mail,</p>
<p>numer telefonu,</p>
<p>dane zawarte w korespondencji do nas kierowanej,</p>
<p>numer rachunku bankowego,</p>
<p>adres IP,</p>
<p>wizerunek (zdjęcie profilowe),</p>
<p>produkty, które oglądałeś,</p>
<p>szczegóły dotyczące złożonych zamówień,</p>
<p>szczegóły dotyczące porzuconych koszyków,</p>
<p>aktywność w odniesieniu do wiadomości wysyłanych w ramach newslettera,</p>
<p>informacje o systemie operacyjnym i przeglądarce internetowej, z której korzystasz,</p>
<p>przeglądane postronny,</p>
<p>czas spędzony na stronie,</p>
<p>przejścia pomiędzy poszczególnymi podstronami,</p>
<p>kliknięcia w poszczególne linki,</p>
<p>źródło, z którego przechodzisz do naszej strony,</p>
<p>przedział wieku, w którym się znajdujesz,</p>
<p>Twoja płeć,</p>
<p>Twoja przybliżona lokalizacja ograniczona do miejscowości,</p>
<p>Twoje zainteresowania określone na podstawie aktywności w sieci.</p>
<p>Zakres przetwarzanych danych opisaliśmy precyzyjnie w odniesieniu do każdego celu przetwarzania. Informacje w tym zakresie znajdują się w dalszej części niniejszej polityki.</p>
<p># 4: Skąd mamy Twoje dane osobowe? W większości przypadków sam nam je przekazujesz. Dzieje się tak, gdy:<br />
rejestrujesz konto użytkownika,</p>
<p>składasz zamówienie,</p>
<p>przesyłasz reklamacje lub odstępujesz od umowy,</p>
<p>zapisujesz się do newslettera,</p>
<p>dodajesz komentarz lub opinię o produkcie,</p>
<p>kontaktujesz się z nami.</p>
<p>Ponadto, część informacji na Twój temat może być automatycznie gromadzona przez narzędzia, z których korzystamy:</p>
<p>mechanizm strony i systemu newsletterowego gromadzą Twój adres IP,</p>
<p>mechanizm strony gromadzi informacje na temat produktów, które oglądałeś, szczegółów składanych zamówień, również tych niedokończonych,</p>
<p>mechanizm systemu newsletterowego gromadzi informacje na temat Twojej aktywności w odniesieniu do treści przesyłanych Ci w ramach newslettera, takich jak otwarcia wiadomości, klikanie w linki itp.,</p>
<p>Google Analytics gromadzi szereg informacji na temat tego, w jaki sposób korzystasz z naszej strony.</p>
<p># 5: Czy Twoje dane są bezpieczne?<br />
Dbamy o bezpieczeństwo Twoich danych osobowych. Przeanalizowaliśmy ryzyka, jakie wiążą się z poszczególnymi procesami przetwarzania Twoich danych, a następnie wdrożyliśmy odpowiednie środki bezpieczeństwa i ochrony danych osobowych. Na bieżąco monitorujemy stan naszej infrastruktury technicznej, szkolimy nasz personel, przyglądamy się stosowanym procedurom, wprowadzamy konieczne usprawnienia. W razie jakichkolwiek pytań dotyczących Twoich danych osobowych, jesteśmy do Twojej dyspozycji pod adresem biuro@genfactor.pl</p>
<p># 6: W jakich celach przetwarzamy Twoje dane osobowe?<br />
Tych celi jest więcej niż jeden. Poniżej znajduje się ich lista, a następnie bardziej szczegółowe omówienie. Poszczególnym celom przyporządkowaliśmy również odpowiednie podstawy prawne przetwarzania.</p>
<p>rejestracja i utrzymywanie konta użytkownika – art. 6 ust. 1 lit. b RODO,</p>
<p>obsługa zamówienia – art. 6 ust. 1 lit. b RODO,</p>
<p>obsługa reklamacji lub odstąpienia od umowy – art. 6 ust. 1 lit. f RODO,</p>
<p>wysyłka newslettera – art. 6 ust. 1 lit. a RODO,</p>
<p>obsługa komentarzy lub opinii o produkcie – art. 6 ust. 1 lit. a RODO,</p>
<p>obsługa korespondencji – art. 6 ust. 1 lit. f RODO,</p>
<p>realizacja obowiązków podatkowych i księgowych – art. 6 ust. 1 lit. c RODO,</p>
<p>tworzenie archiwum na potrzeby ewentualnej konieczności obrony, ustalenia lub dochodzenia roszczeń, jak również w celu identyfikacji klienta powracającego – art. 6 ust. 1 lit. f RODO,</p>
<p>marketing własny – art. 6 ust. 1 lit. f RODO,</p>
<p>analiza, statystyka i optymalizacja – art. 6 ust. 1 lit. f RODO.</p>
<p>Konto użytkownika – szczegóły</p>
<p>Zakładając konto użytkownika, musisz podać dane niezbędne do założenia konta: adres e-mail oraz hasło. Podanie danych jest dobrowolne, ale niezbędne do założenia konta.</p>
<p>W ramach edycji danych konta możesz podać swoje dalej idące dane, w szczególności dane, które mogą być wykorzystywane przy składaniu zamówień, takie jak imię i nazwisko, adres zamieszkania lub miejsca wykonywania działalności, numer NIP, numer telefonu. W ramach edycji danych konta możesz również ustawić swój awatar, np. zdjęcie profilowe obejmujące wizerunek.</p>
<p>Jeżeli zakładasz konto poprzez integrację z kontem w serwisie społecznościowym, na podstawie Twojej uprzedniej autoryzacji uzyskamy dostęp do określonych danych zgromadzonych w ramach konta w serwisie społecznościowym (imię i nazwisko, adres e-mail, zdjęcie profilowe).</p>
<p>Ponadto, nasz system wykorzystywany do obsługi kont użytkowników zapisuje Twój numer IP, z którego korzystałeś, rejestrując konto użytkownika.</p>
<p>Co więcej, wykorzystujemy integrację narzędzia Google Analytics z mechanizmem konta użytkownika. W ten sposób, dane zgromadzone przez kod śledzący Google Analytics na temat Twojego korzystania z naszej strony przypisywane są do Twojego konta użytkownika. Chodzi tu o informacje takie jak:</p>
<p>informacje o systemie operacyjnym i przeglądarce internetowej, z której korzystasz,</p>
<p>przeglądane postronny,</p>
<p>czas spędzony na stronie,</p>
<p>przejścia pomiędzy poszczególnymi podstronami,</p>
<p>kliknięcia w poszczególne linki,</p>
<p>źródło, z którego przechodzisz do naszej strony,</p>
<p>przedział wieku, w którym się znajdujesz,</p>
<p>Twoja płeć,</p>
<p>Twoja przybliżona lokalizacja ograniczona do miejscowości,</p>
<p>Twoje zainteresowania określone na podstawie aktywności w sieci.</p>
<p>Analizujemy takie informacje na Twój temat w celu optymalizacji naszych stron internetowych pod kątem doświadczenia użytkownika, skuteczności i konwersji, co stanowi nasz prawnie uzasadniony interes, o którym mowa w art. 6 ust. 1 lit. f RODO.</p>
<p>W każdej chwili możesz zmodyfikować informacje na Twój temat przekazane nam w związku z rejestracją konta użytkownika. W sytuacji jednak, w której założyłeś konto z wykorzystaniem integracji z kontem w serwisie społecznościowym, dane pobrane z tego serwisu społecznościowego nie są możliwe do zmodyfikowania.</p>
<p>Dane przekazane przez Ciebie w związku z założeniem konta przetwarzane są w celu świadczenia na Twoją rzecz usługi drogą elektroniczną polegającej na zapewnieniu Ci możliwości korzystania z konta użytkownika. Usługa ta świadczona jest na podstawie umowy zawieranej na zasadach opisanych w regulaminie, co oznacza, że w tym zakresie podstawą prawną przetwarzania Twoich danych osobowych jest art. 6 ust. 1 lit. b RODO.</p>
<p>Dane będą przechowywane przez czas funkcjonowania konta użytkownika. W każdej chwili możesz podjąć decyzję o usunięciu konta, przy czym nie doprowadzi to usunięcia z naszej bazy informacji o Twoich zamówieniach złożonych z wykorzystaniem konta. Dane o zamówieniach przechowywane są w naszym archiwum do czasu upływu terminu przedawnienia roszczeń z umowy / przez cały okres funkcjonowania strony ze względu na zapewnienie możliwości identyfikacji klienta powracającego, odtworzenia jego historii zakupowej, przyznanych rabatów itp., co stanowi nasz uzasadniony interes, o którym mowa w art. 6 ust. 1 lit. f RODO.</p>
<p>Zamówienia – szczegóły<br />
Składając zamówienie, musisz podać dane niezbędne do realizacji zamówienia. W zależności od szczegółów zamówienia, katalog danych może być inny. Przykładowo, jeżeli zamawiasz produkty fizyczne, musimy znać adres, pod który doręczyć Ci zamówienie. Jeżeli prosisz o wystawienie faktury VAT na firmę, musimy znać numer NIP oraz adres wykonywania działalności gospodarczej. Podanie danych jest dobrowolne, ale niezbędne do złożenia zamówienia.</p>
<p>Ponadto, nasz system wykorzystywany do obsługi procesu zamówienia, zapisuje Twój numer IP, z którego korzystałeś, składając zamówienie.</p>
<p>Każde zamówienie zapisywane jest w naszej bazie, co oznacza, że Twoim danym osobowym przypisanym do zamówienia towarzyszą również informacje dotyczące zamówienia takie jak zamówione produkty, wybrany sposób płatności, wybrany sposób dostawy, termin dokonania płatności.</p>
<p>Dane zgromadzone w związku z zamówieniem, przetwarzane są w celu wykonania umowy zawieranej poprzez złożenie zamówienia (art. 6 ust. 1 lit. b RODO), wystawienia faktury (art. 6 ust. 1 lit. c RODO w zw. z przepisami regulującymi kwestie wystawiania faktur), uwzględnienia faktury w dokumentacji księgowej oraz realizacji pozostałych obowiązków podatkowych i księgowych (art. 6 ust. 1 lit. c RODO w zw. z przepisami regulującymi kwestie obowiązków podatkowych i księgowych) oraz w celach archiwalnych na potrzeby ewentualnej konieczności obrony, ustalenia lub dochodzenia roszczeń, jak również identyfikacji klienta powracającego, co stanowi nasz prawnie uzasadniony interes (art. 6 ust. 1 lit. f RODO).</p>
<p>Dane o zamówieniach będą przetwarzane przez czas niezbędny do realizacji zamówienia, a następnie do czasu upływu terminu przedawnienia roszczeń z tytułu zawartej umowy. Ponadto, po upływie tego terminu, dane nadal mogą być przez nas przetwarzane w celach archiwalnych na potrzeby ewentualnej konieczności obrony, ustalenia lub dochodzenia roszczeń, jak również identyfikacji klienta powracającego. Pamiętaj również, że mamy obowiązek przechowywać dokumentację księgową, która może zawierać Twoje dane osobowe, przez okres wymagany przepisami prawa.</p>
<p>Reklamacje i odstąpienia od umowy – szczegóły<br />
Jeżeli składasz reklamację lub odstępujesz od umowy, to przekazujesz dane osobowe zawarte w treści reklamacji lub oświadczeniu o odstąpieniu od umowy, które obejmuję imię i nazwisko, adres zamieszkania, numer telefonu, adres e-mail, numer rachunku bankowego. Podanie danych jest dobrowolne, ale niezbędne, by złożyć reklamację lub odstąpić od umowy.</p>
<p>Dane przekazane nam w związku ze złożeniem reklamacji lub odstąpieniem od umowy wykorzystywane są w celu realizacji procedury reklamacyjnej lub procedury odstąpienia od umowy, a następnie w celach archiwalnych, co stanowi nasz prawnie uzasadniony interes (art. 6 ust. 1 lit. f RODO).</p>
<p>Dane będą przetwarzane przez czas niezbędny do realizacji procedury reklamacyjnej lub procedury odstąpienia. Dokumenty reklamacyjne będą przechowywane do czasu upływu terminu uprawnień z rękojmi. Oświadczenia o odstąpieniu od umowy będą przechowywane wraz z dokumentacją księgową przez okres wymagany przez przepisy prawa.</p>
<p>Newsletter – szczegóły<br />
Zapisując się do newslettera, przekazujesz nam swoje imię oraz adres e-mail. Podanie danych jest dobrowolne, ale niezbędne, by zapisać się do newslettera.</p>
<p>Ponadto, nasz system wykorzystywany do obsługi newslettera, zapisuje Twój numer IP, z którego korzystałeś, zapisując się do newslettera, określa Twoją przybliżoną lokalizację, klienta poczty, z którego korzystasz do obsługi poczty e-mail oraz śledzi Twoje działania podejmowane w związku z wysyłanymi do Ciebie wiadomościami. W związku z tym, posiadamy również informacje, które wiadomości otworzyłeś, w ramach których wiadomości kliknąłeś w linki itp.</p>
<p>Dane przekazane nam przez Ciebie w związku z zapisem do newslettera, wykorzystywane są w celu przesyłania Ci newslettera, a podstawą prawną ich przetwarzania jest Twoja zgoda (art. 6 ust. 1 lit. a RODO) wyrażona podczas zapisywania się do newslettera. Jeżeli chodzi o przetwarzanie informacji, które nie pochodzą od Ciebie, a zostały zebrane automatycznie przez nasz system mailingowy, opieramy się w tym zakresie na naszym prawnie uzasadnionym interesie (art. 6 ust. 1 lit. f RODO) polegającym na analizie zachowań subskrybentów newslettera w celu optymalizacji działań mailingowych.</p>
<p>W każdej chwili możesz zrezygnować z otrzymywania newslettera, klikając w dedykowany link znajdujący się w każdej wiadomości wysyłanej w ramach newslettera lub po prostu kontaktując się z nami. Pomimo rezygnacji z newslettera, Twoje dane będą nadal przechowywane w naszej bazie w celu identyfikacji subskrybenta powracającego oraz ewentualnej obrony roszczeń związanych z wysyłaniem Ci newslettera, w szczególności na potrzeby wykazania faktu udzielenia przez Ciebie zgody na otrzymywanie newslettera oraz chwili jej wycofania, co stanowi nasz prawnie uzasadniony interes, o którym mowa w art. 6 ust. 1 lit. f RODO.</p>
<p>W każdej chwili możesz zmodyfikować swoje dane podane na potrzeby otrzymywania newslettera klikając w stosowny link widoczny w każdej wiadomości wysyłanej w ramach newslettera lub po prostu kontaktując się z nami.</p>
<p>Komentarze i opinie o produktach – szczegóły<br />
Dodając komentarz lub opinię o produkcie, musisz podać przynajmniej nazwę użytkownika, która będzie przypisana do komentarza lub opinii (nazwa może zawierać dane osobowe, jak np. imię lub nazwisko) oraz adres e-mail. Podanie tych danych jest dobrowolne, ale niezbędne by dodać komentarz lub opinię. Możesz dodać również swój awatar (może zawierać Twój wizerunek, np. zdjęcie) oraz podać adres swojej strony internetowej, jednak nie jest to obowiązkowe.</p>
<p>Dane przekazane w związku z dodaniem komentarza lub opinii będą przetwarzane w celu opublikowania komentarza lub opinii na stronie. Podstawą przetwarzania jest Twoja zgoda (art. 6 ust. 1 lit. a) wynikająca z przesłania formularza służącego do publikacji komentarza lub opinii. W każdej chwili możesz cofnąć zgodę, domagając się usunięcia komentarza lub opinii.</p>
<p>Twój komentarz lub opinia będą publicznie dostępne na stronie przez czas jej dostępności w Internecie, chyba że wcześniej zażądasz usunięcia komentarza lub opinii. W każdej chwili możesz również zmodyfikować treść komentarza, jak również zmodyfikować przypisane do niego dane jako o osobie, która dodała komentarz lub opinię.</p>
<p>Obsługa korespondencji – szczegóły<br />
Kontaktując się z nami, w sposób naturalny przekazujesz nam swoje dane osobowe zawarte w treści korespondencji, w szczególności adres e-mail oraz imię i nazwisko. Podanie danych jest dobrowolne, ale niezbędne, by nawiązać kontakt.</p>
<p>Twoje dane są w tym przypadku przetwarzane w celu kontaktu z Tobą, a podstawą przetwarzania jest art. 6 ust. 1 lit. f RODO, czyli nasz prawnie uzasadniony interes. Podstawą prawną przetwarzania po zakończeniu kontaktu jest również nasz usprawiedliwiony cel w postaci archiwizacji korespondencji na potrzeby zapewnienia sobie możliwości wykazania określonych faktów w przyszłości (art. 6 ust. 1 lit. f RODO).</p>
<p>Treść korespondencji może podlegać archiwizacji i nie jesteśmy w stanie jednoznacznie określić, kiedy zostanie usunięta. Masz prawo do domagania się przedstawienia historii korespondencji, jaką z nami prowadziłeś (jeżeli podlegała archiwizacji), jak również domagać się jej usunięcia, chyba że jej archiwizacja jest uzasadniona z uwagi na nasze nadrzędne interesy, np. obrona przed potencjalnymi roszczeniami z Twojej strony.</p>
<p>Obowiązki podatkowe i księgowe – szczegóły<br />
Jeżeli wystawiamy na Twoją rzecz fakturę, to wchodzi ona w skład dokumentacji księgowej, która będzie przechowywana przez okres czasu wymagany przez przepisy prawa. Twoje dane osobowe są w takiej sytuacji przetwarzane w celu realizacji ciążących na nas obowiązków podatkowych i księgowych (art. 6 ust. 1 lit. c RODO w zw. z przepisami regulującymi kwestie obowiązków podatkowych i księgowych).</p>
<p>Archiwum – szczegóły<br />
W ramach opisu poszczególnych celów przetwarzania danych osobowych, które znajdują się powyżej, wskazaliśmy terminy przechowywania danych osobowych. Terminy te często związane są z archiwizacją przez nas określonych danych na potrzeby zapewnienia sobie możliwości wykazania określonych faktów w przyszłości, odtworzenia przebiegu współpracy z klientem, wymienionej korespondencji, obrony, ustalenia lub dochodzenia roszczeń. Opieramy się w tym zakresie na naszym prawnie uzasadnionym interesie, o którym mowa w art. 6 ust. 1 lit. f RODO.</p>
<p>Marketing własny – szczegóły<br />
W ramach naszej strony korzystamy z mechanizmu odzyskiwania porzuconych koszyków. W sytuacji, w której rozpoczniesz proces składania zamówienia, ale go nie ukończysz, nasz system odnotuje ten fakt w celu podjęcia działań mających skłonić Cię do finalizacji zamówienia. Tymi działaniami mogą być, w szczególności, wysyłka do Ciebie wiadomości e-mail z zachętą do finalizacji zamówienia lub wyświetlenie targetowanej reklamy podczas przeglądania Internetu.</p>
<p>Analiza, statystyka, optymalizacja – szczegóły<br />
Zbieramy informacje statystyczne na temat zachowania użytkowników podczas przeglądania naszych stron internetowych, takich jak kliknięcia w linki, przejścia pomiędzy podstronami, czas spędzany na poszczególnych stronach itp. Informacje te poddajemy analizie w celu optymalizacji naszych stron pod kątem doświadczenia użytkownika, skuteczności i konwersji. W większości przypadków informacje przetwarzane w ten sposób nie mają charakteru danych osobowych. Wyjątkiem jest sytuacja, w której jesteś zarejestrowanym użytkownikiem. Wtedy możemy zestawiać te informacje z innymi Twoimi danymi zgromadzonymi w koncie użytkownika.</p>
<p>Opisane powyżej działania realizujemy, opierając się o nasz prawnie uzasadniony interes, o którym mowa w art. 6 ust. 1 lit. f RODO polegający na optymalizacji naszych stron internetowych.</p>
<p># 7: Jak długo będziemy przechowywać Twoje dane osobowe?<br />
Okresy przechowywania danych zostały wskazane odrębnie w stosunku do każdego celu przetwarzania. Odnajdziesz te informacje w ramach szczegółów poświęconych każdemu odrębnemu celowi przetwarzania.</p>
<p># 8: Kim są odbiorcy Twoich danych osobowych?<br />
Zaryzykujemy stwierdzenie, że współczesny biznes nie jest w stanie obejść się bez usług świadczonych przez podmioty trzecie. My również z takich usług korzystamy. Część z tych usług wiąże się z przetwarzaniem Twoich danych osobowych. Zewnętrzni usługodawcy, którzy biorą udział w przetwarzaniu Twoich danych osobowych, to:</p>
<p>dostawca usługi chmury obliczeniowej, w której przechowywane są kopie zapasowe mogące zawierać Twoje dane osobowe,<br />
dostawca systemu mailingowego, w którym przechowywane są Twoje dane, jeżeli jesteś subskrybentem newslettera,<br />
dostawca systemu CRM, w którym przechowujemy Twoje dane w celu usprawnienia procesu obsługi klienta oraz w celach archiwalnych,<br />
dostawca systemu do fakturowania, w którym przechowywane są Twoje dane w celu wystawienia faktury,<br />
biuro rachunkowe, które przetwarza Twoje dane widoczne na fakturach,<br />
firmy kurierskie, które przetwarzają Twoje dane w zakresie niezbędnym do dostarczenia Ci zamówienia,<br />
kancelaria prawna, która uzyskuje dostęp do danych, jeżeli jest to konieczne do świadczenia pomocy prawnej na naszą rzecz,<br />
podmiot świadczący usługi w zakresie obsługi technicznej, który uzyskuje dostęp do danych, jeżeli prowadzone prace techniczne dotyczą obszarów, w których znajdują się dane osobowe,<br />
pozostali podwykonawcy, którzy uzyskują dostęp do danych, jeżeli zakres ich działań wymaga takowego dostępu.<br />
Twoje dane osobowe mogą być również przekazywane do urzędów skarbowych w zakresie niezbędnym do realizacji obowiązków podatkowo-rozliczeniowo-księgowych. Chodzi w szczególności o wszelkie deklaracje, raporty, sprawozdania i inne dokumenty księgowe, w których znajdują się Twoje dane osobowe.</p>
<p>Ponadto, w razie zaistnienia takiej konieczności, Twoje dane osobowe mogą być udostępniane podmiotom, organom lub instytucjom uprawnionym do uzyskania dostępu do danych na podstawie przepisów prawa, takim jak służby policyjne, bezpieczeństwa, sądy, prokuratury.</p>
<p>Co więcej, korzystamy z narzędzi, które gromadzą na Twój temat szereg informacji związanych z korzystaniem z naszej strony. Chodzi, w szczególności, o następujące informacje:</p>
<p>informacje o systemie operacyjnym i przeglądarce internetowej, z której korzystasz,<br />
przeglądane postronny,<br />
czas spędzony na stronie,<br />
przejścia pomiędzy poszczególnymi podstronami,<br />
kliknięcia w poszczególne linki,<br />
źródło, z którego przechodzisz do naszej strony,<br />
przedział wieku, w którym się znajdujesz,<br />
Twoja płeć,<br />
Twoja przybliżona lokalizacja ograniczona do miejscowości.<br />
Twoje zainteresowania określone na podstawie aktywności w sieci.<br />
Informacje te same w sobie nie mają, w naszej ocenie, charakteru danych osobowych. Ponieważ informacje te gromadzone są przez zewnętrzne narzędzia, z których korzystamy, informacje te przetwarzane są również przez dostawców narzędzi na zasadach wynikających z ich regulaminów oraz polityk prywatności. Zasadniczo, informacje te wykorzystywane są do świadczenia i ulepszania usług, zarządzania nimi, opracowywania nowych usług, mierzenia skuteczności reklam, ochrony przed oszustwami i nadużyciami, a także personalizowania treści i reklam wyświetlanych w poszczególnych serwisach, witrynach i aplikacjach. Szczegóły w tym zakresie postaraliśmy się opisać w dalszej części niniejszej polityki, w ramach wyjaśnień poświęconych poszczególnym narzędziom.</p>
<p># 9: Czy przekazujemy Twoje dane do państw trzecich lub organizacji międzynarodowych?<br />
Tak, część operacji przetwarzania Twoich danych osobowych może wiązać się z ich przekazywaniem do państw trzecich.</p>
<p>Przekazujemy Twoje dane osobowe do państw trzecich w związku z korzystaniem z narzędzi, które przechowują dane osobowe na serwerach zlokalizowanych w państwach trzecich, w szczególności w USA. Dostawcy tych narzędzi gwarantują odpowiedni poziom ochrony danych osobowych poprzez stosowne mechanizmy zgodności przewidziane przez RODO, w szczególności poprzez przystąpienie do programu Privacy Shield lub korzystanie ze standardowych klauzul umownych.</p>
<p>system mailingowy ActiveCampagne, którego dostawcą jest ActiveCampagne LLC, 1 North Dearborn Street, Suite 500, Chicago, IL 6062, USA – w zakresie Twojego imienia, adresu e-mail, adresu IP oraz informacji statystycznych związanych z Twoimi reakcjami na wysyłane przez nas wiadomości,<br />
usługi Google w ramach pakietu G-Suite, których dostawcą jest Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland – w zakresie wszystkich danych, jakie przetwarzane są w ramach usług Google, w tym również tych danych, które zawarte są w plikach podlegających synchronizacji z Google Drive.<br />
Google: https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI,<br />
Vimeo INC, 555 West 18th Street, Nowy Jork, Nowy Jork 10011-w celu korzystania z nagrań wideo serwisu Vimeo, co wiąże się z wykorzystaniem plików cookies,<br />
Właściciele portali społecznościowych: Facebook, Instagram, LinkedIn, YouTube na zasadach dotyczących przetwarzania danych określonych przez właścicieli tych portali, w sytuacji, gdy polubisz moje profile społecznościowe prowadzone w ramach tych portali.2)<br />
Twoje dane mogą być przekazywane przez odbiorców danych poza terytorium EOG. Gwarancje ochrony Twoich danych wynikają z uczestnictwa odbiorców danych w programie “Tarcza Prywatności” ustanowionym na mocy decyzji wykonawczej Komisji (UE) 2016/1250 z dnia 12 lipca 2016 r. w sprawie adekwatności ochrony zapewnianej przez Tarczę Prywatności UE-USA (ActiveCampaign LLC:  https://www.privacyshield.gov/participant?id=a2zt0000000GnH6AAK, Google LLC: https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active, Vimeo INC: https://www.privacyshield.gov/participant?id=a2zt00000008V77AAE&amp;status=Active)</p>
<p># 10: Czy korzystamy z profilowania? Czy podejmujemy w sposób zautomatyzowany decyzje na podstawie Twoich danych osobowych?<br />
Nie podejmujemy wobec Ciebie decyzji opierających się wyłącznie na zautomatyzowanym przetwarzaniu, w tym profilowaniu, które wywoływałyby wobec Ciebie skutki prawne lub w podobny sposób istotnie na Ciebie wpływały.</p>
<p>Owszem, korzystamy z narzędzi, które mogę podejmować określone działania w zależności od informacji zebranych w ramach mechanizmów śledzących, ale uważamy, że działania te nie mają istotnego wpływu na Ciebie, ponieważ nie różnicują Twojej sytuacji jako klienta, nie wpływają na warunki umowy, jaką możesz z nami zawrzeć itp.</p>
<p>Korzystając z określonych narzędzi, możemy np. kierować do Ciebie spersonalizowane reklamy w oparciu o wcześniejsze działania podjęte przez Ciebie w ramach naszej strony czy też podpowiadać Ci produkty, które mogą Cię zainteresować. Mowa tutaj o tzw. reklamie behawioralnej. Zachęcamy Cię do pogłębienia wiedzy na temat reklamy behawioralnej, w szczególności w zakresie kwestii związanych z prywatnością. Szczegółowe informacje, wraz z możliwością zarządzania ustawieniami w zakresie reklamy behawioralnej, znajdziesz tutaj: http://www.youronlinechoices.com.</p>
<p>Podkreślamy, że w ramach narzędzi, z których korzystamy nie mamy dostępu do informacji, które pozwalałby na Twoją identyfikację. Informacje, o których tutaj mówimy, to, w szczególności:</p>
<p>informacje o systemie operacyjnym i przeglądarce internetowej, z której korzystasz,<br />
przeglądane postronny,<br />
czas spędzony na stronie,<br />
przejścia pomiędzy poszczególnymi podstronami,<br />
źródło, z którego przechodzisz do naszej strony,<br />
przedział wieku, w którym się znajdujesz,<br />
Twoja płeć,<br />
Twoja przybliżona lokalizacja ograniczona do miejscowości,<br />
Twoje zainteresowania określone na podstawie aktywności w sieci.<br />
Informacji wskazanych powyżej nie zestawiamy z Twoimi danymi osobowymi, które znajdują się w naszych bazach. Informacje te mają charakter anonimowy i nie pozwalają nam na Twoją identyfikację. Informacje te przechowywane są na serwerach dostawców poszczególnych narzędzi, a serwery te najczęściej mogą znajdować się na całym świecie.</p>
<p>Wyjątkiem od anonimowego charakteru informacji, o których mowa powyżej, jest sytuacja, w której posiadasz konto użytkownika. Wtedy informacje te mogą być zestawiane z Twoimi danymi zgromadzonymi w koncie użytkownika. W dalszym ciągu jednak nie podejmujemy, z wykorzystaniem tych informacji, wobec Ciebie decyzji opierających się wyłącznie na zautomatyzowanym przetwarzaniu, w tym profilowaniu, które wywoływałyby wobec Ciebie skutki prawne lub w podobny sposób istotnie na Ciebie wpływały. Uważamy, że działania w zakresie kierowania do Ciebie reklam w zależności od Twojej aktywności na naszej stronie oraz podejmowanie działań optymalizacyjnych nie wpływa na Ciebie w istotny sposób. Dlatego w tym zakresie opieramy się na naszym prawnie uzasadnionym interesie, o którym mowa w art. 6 ust. 1 lit. f RODO.</p>
<p># 11: Jakie uprawnienia przysługują Ci w związku z przetwarzaniem Twoich danych osobowych?<br />
RODO przyznaje Ci następujące potencjalne uprawnienia związane z przetwarzaniem Twoich danych osobowych:</p>
<p>prawo dostępu do swoich danych oraz otrzymania ich kopii,<br />
prawo do sprostowania (poprawiania) swoich danych,<br />
prawo do usunięcia danych (jeżeli Twoim zdaniem nie ma podstaw, abyśmy przetwarzali Twoje dane, możesz żądać, abyśmy je usunęli),<br />
prawo do ograniczenia przetwarzania danych (możesz żądać, abyśmy ograniczyli przetwarzanie danych wyłącznie do ich przechowywania lub wykonywania uzgodnionych z Tobą działań, jeżeli w Twojej opinii mamy nieprawidłowe dane lub przetwarzamy je bezpodstawnie),<br />
prawo do wniesienia sprzeciwu wobec przetwarzania danych (masz prawo do sprzeciwu wobec przetwarzania danych na podstawie prawnie uzasadnionego interesu; powinieneś wskazać szczególną sytuację, która Twoim zdaniem uzasadnia zaprzestanie przez nas przetwarzania objętego sprzeciwem; przestaniemy przetwarzać Twoje dane w tych celach, chyba że wykażemy, że podstawy przetwarzania przez nas danych są nadrzędne wobec Twoich praw lub też, że Twoje dane są nam niezbędne do ustalenia, dochodzenia lub obrony roszczeń),<br />
prawo do przenoszenia danych (masz prawo otrzymać od nas w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego dane osobowe, które dostarczyłeś nam na podstawie umowy lub Twojej zgody; możesz nam zlecić przesłanie tych danych bezpośrednio innemu podmiotowi),<br />
prawo do cofnięcia zgody na przetwarzanie danych osobowych, jeżeli uprzednio taką zgodę wyraziłeś,<br />
prawo wniesienia skargi do organu nadzorczego (jeżeli stwierdzisz, że przetwarzamy dane niezgodnie z prawem, możesz złożyć w tej sprawie skargę do Prezesa Urzędu Ochrony Danych Osobowych lub innego właściwego organu nadzorczego).<br />
Zasady związane z realizacją wskazanych powyżej uprawnień zostały opisane szczegółowo w art. 16 – 21 RODO. Zachęcamy do zapoznania się z tymi przepisami. Ze swojej strony uważamy za potrzebne wyjaśnić Ci, że wskazane powyżej uprawnienia nie są bezwzględne i nie będą przysługiwać Ci w stosunku do wszystkich czynności przetwarzania Twoich danych osobowych.</p>
<p>Podkreślamy, że jedno z uprawnień wskazanych powyżej przysługuje Ci zawsze – jeżeli uznasz, że przy przetwarzaniu Twoich danych osobowych dopuściliśmy się naruszenia przepisów o ochronie danych osobowych, masz możliwość wniesienia skargi do organu nadzorczego (Prezesa Urzędu Ochrony Danych Osobowych).</p>
<p>Zawsze możesz również zwrócić się do nas z żądaniem udostępnienia Ci informacji o tym, jakie dane na Twój temat posiadamy oraz w jakich celach je przetwarzamy. Wystarczy, że wyślesz wiadomość na adres biuro@genfactor.pl. Dołożyliśmy jednak wszelkich starań, by interesujące Cię informacje zostały wyczerpująco przedstawione w niniejszej polityce prywatności. Podany powyżej adres e-mail możesz wykorzystać również w razie jakichkolwiek pytań związanych z przetwarzaniem Twoich danych osobowych.</p>
<p># 12: Czy korzystamy z cookies i czym one właściwie są?<br />
Nasza strona, podobnie jak niemal wszystkie inne strony internetowe, wykorzystuje pliki cookies.</p>
<p>Cookies to niewielkie informacje tekstowe, przechowywane na Twoim urządzeniu końcowym (np. komputerze, tablecie, smartfonie), które mogą być odczytywane przez nasz system teleinformatyczny (cookies własne) lub systemy teleinformatyczne podmiotów trzecich (cookies podmiotów trzecich). W plikach cookies mogą być zapisywane i przechowywane określone informacje, do których następnie systemy teleinformatyczne mogą uzyskiwać dostęp w określonych celach.</p>
<p>Niektóre używane przez nas cookies są usuwane po zakończeniu sesji przeglądarki internetowej, tzn. po jej zamknięciu (tzw. cookies sesyjne). Inne cookies są zachowywane na Twoim urządzeniu końcowym i umożliwiają nam rozpoznanie Twojej przeglądarki przy kolejnym wejściu na stronę (trwałe cookies).</p>
<p>Jeżeli chcesz dowiedzieć się więcej o plikach cookies jako takich, możesz zapoznać się np. z tym materiałem: https://pl.wikipedia.org/wiki/HTTP_cookie.</p>
<p># 13: Na jakiej podstawie korzystamy z plików cookies?<br />
Z plików cookies korzystamy na podstawie Twojej zgody, za wyjątkiem sytuacji, gdy pliki cookies są niezbędne do prawidłowego świadczenia na Twoją rzecz usługi drogą elektroniczną.</p>
<p>Pliki cookies, które nie są niezbędne do prawidłowego świadczenia usługi drogą elektroniczną, pozostają zablokowane do czasu wyrażenia przez Ciebie zgody na korzystanie przez nas z plików cookies. Podczas Twojej pierwszej wizyty na naszej stronie wyświetlamy Ci komunikat z pytaniem o Twoją zgodę wraz z możliwością zarządzania plikami cookies, tj. zdecydowania, na które pliki cookies się godzisz, a które chcesz blokować.</p>
<p>W zakresie Twojej zgody na pliki cookies, przyjmujemy wariant, zgodnie z którym taką zgodę wyrażasz poprzez ustawienia swojej przeglądarki internetowej lub dodatkowego oprogramowania wspierającego zarządzanie plikami cookies. Przyjmujemy, że godzisz się na wszystkie pliki cookies przez nas stosowane, które nie są blokowane przez Twoją przeglądarkę lub dodatkowe oprogramowanie, z którego korzystasz.</p>
<p>Pamiętaj, że wyłączenie lub ograniczenie obsługi plików cookies może uniemożliwiać używanie części funkcji dostępnych na stronie oraz powodować trudności w korzystaniu z naszej strony, jak również z wielu innych stron internetowych, które stosują cookies. Przykładowo, jeżeli zablokujesz pliki cookies wtyczek serwisów społecznościowych, przyciski, widgety oraz funkcje społecznościowe zaimplementowane na naszej stronie mogą być dla Ciebie niedostępne.</p>
<p># 14: Czy możesz wyłączyć pliki cookies?<br />
Tak, możesz zarządzać ustawieniami plików cookies w ramach swojej przeglądarki internetowej. Możesz blokować wszystkie lub wybrane pliki cookies. Możesz również blokować pliki cookies konkretnych witryn. W każdej chwili możesz również usunąć zapisane wcześniej pliki cookies oraz inne dane witryn i wtyczek.</p>
<p>Przeglądarki internetowe oferują również możliwość korzystania z trybu incognito. Możesz z niego skorzystać, jeśli nie chcesz, by informacje o odwiedzonych stronach i pobranych plikach zostały zapisane w historii przeglądania i pobierania. Pliki cookie utworzone w trybie incognito są usuwane w momencie zamknięcia wszystkich okien tego trybu.</p>
<p>Dostępne są również wtyczki do przeglądarek umożliwiające kontrolę nad plikami cookies, takie jak np. Ghostery (https://www.ghostery.com). Opcję kontroli nad plikami cookies może zapewniać również dodatkowe oprogramowanie, w szczególności pakiety antywirusowe itp.</p>
<p>Ponadto, w Internecie dostępne są narzędzia pozwalające na kontrolę nad niektórymi rodzajami plików cookies, w szczególności na zbiorowe zarządzanie ustawieniami reklamy behawioralnej (np. www.youronlinechoices.com/, www.networkadvertising.org/choices).</p>
<p># 15: W jakich celach korzystamy z plików cookies własnych?<br />
Pliki cookies własne wykorzystywane są do zapewnienia prawidłowego funkcjonowania poszczególnych mechanizmów strony, takich jak utrzymywanie sesji po zalogowaniu do konta, zapamiętanie ostatnio przeglądanych produktów oraz produktów dodanych do koszyka.</p>
<p># 16: Jakie pliki cookies podmiotów trzecich są wykorzystywane?<br />
W ramach naszej strony funkcjonują następujące pliki cookies podmiotów trzecich:</p>
<p>Google Analytics,<br />
Google Tag Manager,<br />
Google AdWords,<br />
Google AdSense,<br />
Facebook Custom Audiences,<br />
Facebook, Twitter, LinkedIN, Pinterest (pliki cookies narzędzi społecznościowych),<br />
Vimeo,<br />
Hotjar,<br />
YouTube.<br />
Szczegóły dotyczące poszczególnych plików cookies podmiotów trzecich opisane zostały poniżej.</p>
<p>Google Analytics – szczegóły<br />
Korzystamy z narzędzia Google Analytics zapewnianego przez Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Działania w tym zakresie realizujemy, opierając się na naszym prawnie uzasadnionym interesie, polegającym na tworzeniu statystyk i ich analizie w celu optymalizacji naszych stron internetowych.</p>
<p>W celu korzystania z Google Analytics, zaimplementowaliśmy w kodzie naszej strony specjalny kod śledzący Google Analytics. Kod śledzący wykorzystuje pliki cookies firmy Google LLC dotyczące usługi Google Analytics. W każdej chwili możesz zablokować kod śledzący Google Analytics, instalując dodatek do przeglądarki udostępniany przez Google: https://tools.google.com/dlpage/gaoptout.</p>
<p>Google Analytics w sposób automatyczny gromadzi informacje o Twoim korzystaniu z naszej strony. Zgromadzone w ten sposób informacje są najczęściej przekazywane do serwerów Google, które mogą znajdować się w całym świecie i tam przechowywane.</p>
<p>Z uwagi na aktywowaną przez nas anonimizację adresu IP, Twój adres IP jest skracany przed przekazaniem dalej. Tylko w wyjątkowych przypadkach pełny adres IP jest przekazywany do serwerów Google i dopiero tam skracany. Zanonimizowany adres IP przekazany przez Twoją przeglądarkę w ramach Google Analytics co do zasady nie jest łączony z innymi danymi Google.</p>
<p>Podkreślamy, że w ramach Google Analytics nie gromadzimy jakichkolwiek danych, które pozwalałaby na Twoją identyfikację. W związku z tym, dane gromadzone w ramach Google Analytics nie mają dla nas charakteru danych osobowych. Informacje, do jakich mamy dostęp w ramach Google Analytics to, w szczególności:</p>
<p>informacje o systemie operacyjnym i przeglądarce internetowej, z której korzystasz,</p>
<p>podstrony, jakie przeglądasz w ramach naszej strony,</p>
<p>czas spędzony na naszej stronie oraz na jego podstronach,</p>
<p>przejścia pomiędzy poszczególnymi podstronami,</p>
<p>źródło, z którego przechodzisz do naszej strony.</p>
<p>Ponadto, korzystamy w ramach Google Analytics z następujących Funkcji Reklamowych:</p>
<p>raporty demograficzne i zainteresowań,<br />
remarketing,<br />
funkcje raportowania o reklamach, user-ID.<br />
W ramach Funkcji Reklamowych również nie gromadzimy danych osobowych. Informacje, do jakich mamy dostęp, to, w szczególności:</p>
<p>przedział wieku, w którym się znajdujesz,<br />
Twoja płeć,<br />
Twoja przybliżona lokalizacja ograniczona do miejscowości,<br />
Twoje zainteresowania określone na podstawie aktywności w sieci.<br />
Usługi Google Analytics i Google Analytics 360 uzyskały certyfikat niezależnego standardu bezpieczeństwa ISO 27001. ISO 27001 jest jednym z najczęściej uznawanych standardów na świecie i poświadcza spełnianie odpowiednich wymogów przez systemy obsługujące Google Analytics i Google Analytics 360.</p>
<p>Jeżeli jesteś zainteresowany szczegółami związanymi z wykorzystywaniem przez Google danych z witryn i aplikacji, które korzystają z usług Google, zachęcamy do zapoznania się z tymi informacjami: https://policies.google.com/technologies/partner-sites.</p>
<p>Google Tag Manager – szczegóły<br />
Korzystamy z narzędzia Google Tag Manager zapewnianego przez Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA i umożliwiającego zarządzanie tagami, czyli drobnymi fragmentami kodu, dzięki którym jesteśmy w stanie kontrolować ruch i zachowanie użytkowników, zbierać informacje o skuteczności reklam i podejmować działania mające na celu ulepszanie naszej strony. Google Tag Manager nie zbiera żadnych informacji umożliwiających Twoją identyfikację, jednakże narzędzie to powoduje uruchomienie innych tagów, które z kolei mogą zbierać dane.</p>
<p>Google AdWords – szczegóły<br />
Korzystamy z funkcji remarketingowych dostępnych w ramach systemu Google AdWords obsługiwanego przez Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Działania w tym zakresie realizujemy, opierając się na naszym prawnie uzasadnionym interesie, polegającym na marketingu własnych produktów lub usług.</p>
<p>Przy okazji odwiedzin naszej strony internetowej automatycznie pozostawiany jest w Twoim urządzeniu plik remarketing cookie firmy Google, który zbiera informacje na temat Twojej aktywności na naszej stronie. Dzięki zgromadzonym w ten sposób informacją jesteśmy w stanie wyświetlać Ci reklamy w ramach sieci Google w zależności od Twoich zachowań na naszej stronie. Przykładowo, jeżeli wyświetlisz jakiś produkt, informacja o tym fakcie zostanie odnotowana przez plik remarketing cookie, co sprawi, że będziemy mogli skierować do Ciebie reklamę dotyczącą tego produktu lub jakąkolwiek inną, którą uznamy za właściwą. Reklama ta zostanie wyświetlona Ci w ramach sieci Google podczas korzystania przez Ciebie z Internetu, przeglądania innych stron internetowych itp.</p>
<p>Podkreślamy, że my, korzystając z Google Ads, nie gromadzimy jakichkolwiek danych, które pozwalałaby na Twoją identyfikację. Ewentualne zestawienie danych w taki sposób, że nabierają one charakteru danych osobowych, może być dokonywane po stronie Google, ale w tym zakresie nie ponosimy już za to odpowiedzialności, ponieważ Google realizuje te działania na podstawie umowy zawartej z Tobą jako użytkownikiem usług Google.</p>
<p>My, korzystając z Google AdWords, jesteśmy jedynie w stanie definiować grupy odbiorców, do których chcielibyśmy, by nasze reklamy docierały. Na tej podstawie Google podejmuje decyzję, kiedy i w jaki sposób zaprezentuje Ci naszą reklamę.</p>
<p>Dalsze przetwarzanie informacji ma miejsce tylko wtedy, gdy wyraziłeś zgodę wobec Google na łączenie historii przeglądania z Twoim kontem oraz wykorzystanie informacji z Twojego konta Google do personalizowania reklam, które wyświetlane są na stronach internetowych. W takiej sytuacji Google wykorzysta Twoje dane w celu stworzenia i zdefiniowania list grup docelowych dla celów remarketingu na różnych urządzeniach. W tym celu Google łączy tymczasowo zebrane informacje z innymi posiadanymi danymi, aby utworzyć grupy docelowe.</p>
<p>Jeżeli nie chcesz otrzymywać personalizowanych reklam, możesz zarządzać ustawieniami reklam bezpośrednio po stronie Google: https://adssettings.google.com/.</p>
<p>Jeżeli jesteś zainteresowany szczegółami związanymi z wykorzystywaniem przez Google danych z witryn i aplikacji, które korzystają z usług Google, zachęcamy do zapoznania się z tymi informacjami: https://policies.google.com/technologies/partner-sites.</p>
<p>Google AdSense – szczegóły<br />
Wyświetlamy na naszych stronach reklamy w ramach sieci Google AdSense obsługiwanej przez Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Działania w tym zakresie realizujemy, opierając się na naszym prawnie uzasadnionym interesie, polegającym na monetyzacji treści przez nas publikowanych.</p>
<p>Nasza strona, w związku z wyświetlaniem na niej reklam w ramach sieci AdSense, zawiera tagi reklam, które wydają Twojej przeglądarce internetowej polecenie wysłania żądania treści reklamowej z serwerów Google. Wraz z treścią reklamową serwer wysyła także plik cookie. Pliki cookies wykorzystywane są do wyświetlania reklam na podstawie Twoich poprzednich odwiedzin na naszej stronie lub w innych witrynach internetowych. AdSense używa plików cookie również po to, by poprawiać jakość reklam. Często zastosowania to m.in. kierowanie reklam na podstawie tematów, które Cię interesują, ulepszanie raportów o skuteczności kampanii i pomijanie reklam, które już widziałeś.</p>
<p>Podkreślamy, że my, korzystając z Google AdSense, nie gromadzimy jakichkolwiek danych, które pozwalałaby na Twoją identyfikację. Ewentualne zestawienie danych w taki sposób, że nabierają one charakteru danych osobowych, może być dokonywane po stronie Google, ale w tym zakresie nie ponosimy już za to odpowiedzialności, ponieważ Google realizuje te działania na podstawie umowy zawartej z Tobą jako użytkownikiem usług Google.</p>
<p>Dalsze przetwarzanie informacji ma miejsce tylko wtedy, gdy wyraziłeś zgodę wobec Google na łączenie historii przeglądania z Twoim kontem oraz wykorzystanie informacji z Twojego konta Google do personalizowania reklam, które wyświetlane są na stronach internetowych. W takiej sytuacji Google wykorzysta Twoje dane w celu stworzenia i zdefiniowania list grup docelowych dla celów remarketingu na różnych urządzeniach. W tym celu Google łączy tymczasowo zebrane informacje z innymi posiadanymi danymi, aby utworzyć grupy docelowe.</p>
<p>Jeżeli nie chcesz otrzymywać personalizowanych reklam, możesz zarządzać ustawieniami reklam bezpośrednio po stronie Google: https://adssettings.google.com/.</p>
<p>Jeżeli jesteś zainteresowany szczegółami związanymi z wykorzystywaniem przez Google danych z witryn i aplikacji, które korzystają z usług Google, zachęcamy do zapoznania się z tymi informacjami: https://policies.google.com/technologies/partner-sites.</p>
<p>Facebook Custom Audiences – szczegóły<br />
W ramach systemu reklam Facebook Ads zapewnianego przez Facebook Inc., 1601 S. California Ave., Palo Alto, CA 94304, USA, korzystamy z funkcji Niestandardowych Grup Odbiorców w celu kierowania do określonych grup użytkowników targetowanych przekazów reklamowych. Działania w tym zakresie realizujemy, opierając się na naszym prawnie uzasadnionym interesie, polegającym na marketingu własnych produktów lub usług.</p>
<p>W celu kierowania do Ciebie reklam spersonalizowanych pod kątem Twoich zachowań na naszej stronie, zaimplementowaliśmy w ramach naszej strony Pixel Facebooka, który w sposób automatyczny gromadzi informacje o Twoim korzystaniu z naszej witryny. Zgromadzone w ten sposób informacje są najczęściej przekazywane do serwerów Facebooka, która mogą znajdować się na całym świecie, w szczególności w Stanach Zjednoczonych Ameryki (USA).</p>
<p>Informacje zbierane w ramach Pixela Facebooka są anonimowe, tj. nie pozwalają nam na Twoją identyfikację. W zależności od Twojej aktywności na naszych stronach możesz trafić do określonej grupy odbiorców, ale w żaden sposób nie identyfikujemy poszczególnych osób należących do tych grup.</p>
<p>Informujemy Cię jednak, że Facebook może łączyć zbierane informacje z innymi informacjami o Tobie zebranymi w ramach korzystania przez Ciebie z serwisu Facebook i wykorzystywać dla swoich własnych celów, w tym marketingowych. Takie działania Facebooka nie są już zależne od nas, a informacji o nich możesz szukać bezpośrednio w polityce prywatności Facebooka: https://www.facebook.com/privacy/explanation. Z poziomu swojego konta na Facebooku możesz również zarządzać swoimi ustawieniami prywatności. Tutaj znajdziesz przydatne informacje w tym zakresie: https://www.facebook.com/ads/settings.</p>
<p>Narzędzia społecznościowe – szczegóły<br />
Na naszej stronie internetowej używane są wtyczki, przyciski i inne narzędzia społecznościowe, zwane dalej łącznie „wtyczkami”, udostępniane przez serwisy społecznościowe takie jak Facebook, Instagram, LinkedIN, Twitter, Pinterest.</p>
<p>Wyświetlając naszą stronę internetową zawierającą wtyczkę danego serwisu społecznościowego, Twoja przeglądarka przesyła do administratora tego serwisu społecznościowego informację o odwiedzinach. Ponieważ wtyczka jest fragmentem serwisu społecznościowego wbudowanym w naszą stronę, przeglądarka wysyła informację o żądaniu pobrania zawartości danego serwisu społecznościowego na naszą stronę.</p>
<p>Wtyczki gromadzą pewne informacje na Twój temat, takie jak identyfikator użytkownika, odwiedzana witryna, data i godzina oraz inne informacje dotyczące przeglądarki internetowej.</p>
<p>Administratorzy serwisów społecznościowych wykorzystują część tych informacji, aby personalizować warunki oglądania naszej strony. Na przykład, gdy odwiedzasz stronę z przyciskiem „Lubię to”, administrator serwisu społecznościowego potrzebuje informacji, kim jesteś, aby pokazać Ci, którzy z Twoich znajomych również lubią naszą stronę.</p>
<p>Informacje gromadzone przez wtyczki mogą być również wykorzystywane przez administratorów serwisów społecznościowych w ich własnych celach, takich jak np. doskonalenie własnych produktów, tworzenie profili użytkowników, analiza i optymalizacja własnych działań, targetowanie reklam. Nie mamy realnego wpływu na to, w jaki sposób informacje gromadzone przez wtyczki są następnie wykorzystywane przez administratorów serwisów społecznościowych. Szczegółów w tym zakresie możesz szukać w regulaminach i politykach prywatności poszczególnych serwisów społecznościowych.</p>
<p>Wtyczki serwisów społecznościowych gromadzą i przekazują informacje do administratorów tych serwisów nawet wtedy, gdy przeglądasz naszą stroną nie będąc zalogowanym do swojego konta w serwisie społecznościowym. Wtedy jednak przeglądarka wysyła bardziej ograniczony zestaw informacji.</p>
<p>Jeśli zalogowałeś się do jednego z serwisów społecznościowych, to administrator serwisu będzie mógł bezpośrednio przyporządkować wizytę na naszej stronie do Twojego profilu w danym serwisie społecznościowym.</p>
<p>Jeśli nie chcesz, aby serwisy społecznościowe przyporządkowywały dane zebrane w trakcie odwiedzin na naszej stronie internetowej bezpośrednio Twojemu profilowi w danym serwisie, to przed wizytą na naszej stronie musisz się wylogować z tego serwisu. Możesz również całkowicie uniemożliwić załadowanie na stronie wtyczek stosując odpowiednie rozszerzenia dla Twojej przeglądarki, np. blokowanie skryptów.</p>
<p>Ponadto, korzystanie z niektórych wtyczek może wiązać się z publikowaniem określonych informacji w ramach Twoich profili społecznościowych. Przykładowo, informacje o kliknięciach w przycisk „Lubię to” mogą być dostępne na Twojej osi czasu na Facebooku. Oczywiście, jeżeli udostępniasz jakieś treści w swoich social media z wykorzystaniem wtyczek osadzonych na naszej stronie, to udostępnienie to w sposób naturalny będzie widoczne w Twoim profilu.</p>
<p>Jeżeli chodzi o szczegóły związane z przetwarzaniem przez administratorów serwisów społecznościowych informacji gromadzonych przez wtyczki, w szczególności o cel i zakres gromadzenia danych oraz ich dalsze przetwarzanie i wykorzystanie przez administratorów, jak również możliwość kontaktu oraz Twoje prawa w tym zakresie i możliwość dokonania ustawień zapewniających ochronę Twojej prywatności, wszystko znajdziesz w politykach prywatności poszczególnych usługodawców:</p>
<p>Facebook – https://www.facebook.com/privacy/explanation,<br />
Twitter – https://twitter.com/en/privacy,<br />
LinkedIN – https://www.linkedin.com/legal/privacy-policy,<br />
Instagram – https://www.facebook.com/help/instagram/155833707900388,<br />
Pinterest – https://policy.pinterest.com/pl/privacy-policy.<br />
Hotjar – szczegóły<br />
Korzystamy z narzędzia Hotjar, żeby lepiej zrozumieć Twoje potrzeby oraz optymalizować naszą stronę pod kątem Twoich doświadczeń związanych z korzystaniem z niej, co stanowi nasze prawnie uzasadniony interes. Narzędzie zapewniane jest przez zewnętrzny podmiotu, tj. Hotjar Limited, Level 2, St Julian’s Business Centre, 3, Elia Zammit Street, St Julian’s STJ 1000, Malta.</p>
<p>Hotjar rejestruje każdego odwiedzającego naszą stronę i umożliwia odtworzenie nagrania wideo z jego ruchu na naszej stronie, jak również wygenerowania tzw. map cieplnych. W ramach narzędzia Hotjar nie mamy dostępu do informacji, które pozwalają nam na Twoją identyfikację, ponieważ Hotjar nie rejestruje procesu wypełnienia formularzy. Informacje, do jakich mamy dostęp w ramach Hotjar to, w szczególności:</p>
<p>informacje o systemie operacyjnym i przeglądarce internetowej, z której korzystasz,<br />
podstrony, jakie przeglądasz w ramach naszej serwisu,<br />
czas spędzony w naszym serwisie oraz na jego podstronach,<br />
przejścia pomiędzy poszczególnymi podstronami w ramach naszej serwisu,<br />
źródło, z którego przechodzisz do naszej serwisu,<br />
miejsca, w które klikasz myszką.<br />
W celu korzystania z Hotjar, zaimplementowaliśmy w kodzie naszej strony specjalny kod śledzący Hotjar. Kod śledzący wykorzystuje pliki cookies firmy Hotjar Limited. Zgromadzone w ramach plików cookies informacje przechowywane są przez Hotjar w ramach pseudonimowego profilu użytkownika. Ani Hotjar ani my wykorzystujemy tych informacji do Twojej identyfikacji.</p>
<p>Możesz sprzeciwić się tworzeniu przez Hotjar Twojego profilu użytkownika, przechowywania przez Hotjar informacji na temat Twojego korzystania z naszej strony oraz wykorzystywania plików cookies Hotjar tutaj: https://www.hotjar.com/legal/compliance/opt-out.</p>
<p>Jeżeli jesteś zainteresowany szczegółami związanymi z przetwarzaniem danych w ramach Hotjar, zachęcamy do zapoznania się z polityką prywatności Hotjar: https://www.hotjar.com/legal/policies/privacy.</p>
<p>YouTube – szczegóły<br />
Na naszych stronach osadzane są widgety YouTube pozwalające Ci odtwarzać nagrania dostępne w serwisie YouTube bezpośrednio z naszych stron. Serwis YouTube obsługiwany jest przez Google LLC.</p>
<p>Wideo osadzane są na stronie w trybie ochrony prywatności. Bazując na informacjach udostępnianych przez YouTube, oznacza to, że żadne pliki cookies nie są zapisywane na Twoim urządzeniu ani Google nie gromadzi jakichkolwiek informacji na Twój temat, dopóki nie odtworzysz nagrania.</p>
<p>Gdy odtwarzasz nagranie, YouTube zapisuje na Twoim urządzeniu pliki cookies i otrzymuje informacje, że odtworzyłeś nagranie z poziomu określonej strony internetowej, nawet jeśli nie posiadasz konta Google czy nie jesteś akurat zalogowany. Jeśli zalogowałeś się do konta Google, to usługodawca ten będzie mógł bezpośrednio przyporządkować wizytę na naszej stronie do Twojego konta. Cel i zakres gromadzenia danych oraz ich dalszego przetwarzania i wykorzystania przez Google, jak również możliwość kontaktu oraz Twoje prawa w tym zakresie i możliwość dokonania ustawień zapewniających ochronę Twojej prywatności zostały opisane w polityce prywatności Google.</p>
<p>Jeśli nie chcesz, aby Google przyporządkowywał dane zebrane w trakcie odtwarzania wideo bezpośrednio Twojemu profilowi, przed odtworzeniem wideo musisz się wylogować ze swojego konta. Możesz również całkowicie uniemożliwić załadowanie na stronie wtyczek stosując odpowiednie rozszerzenia dla Twojej przeglądarki, np. blokowanie skryptów.</p>
<p>Informacje gromadzone w ramach plików cookies związanych z osadzonymi na naszych stronach filmami z serwisu YouTube wykorzystywane są przez Google w celu zapewnienia prawidłowego i bezpiecznego funkcjonowania widgetu, analizy i optymalizacji w zakresie świadczonych przez YouTube usług, jak również w celach personalizacji i reklamy.</p>
<p>Pamiętaj, że odtwarzając nagrania dostępne w serwisie YouTube, korzystasz z usług świadczonych drogą elektroniczną przez Google LLC. Google LLC jest samodzielnym, niezależnym od nas podmiotem świadczącym na Twoją rzecz usługi drogą elektroniczną. Szczegółów w zakresie zasad korzystania z YouTube, w tym ochrony prywatności, możesz szukać w dokumentach udostępnianych bezpośrednio przez YouTube:</p>
<p>regulamin: https://www.youtube.com/t/terms,</p>
<p>polityka prywatności: https://policies.google.com/privacy.</p>
<p>Vimeo – szczegóły<br />
Na naszych stronach osadzane są widgety Vimeo pozwalające Ci odtwarzać nagrania dostępne w serwisie Vimeo bezpośrednio z naszych stron. Serwis Vimeo obsługiwany jest przez Vimeo INC.</p>
<p>Wideo osadzane są na stronie w trybie ochrony prywatności. Bazując na informacjach udostępnianych przez Vimeo, oznacza to, że żadne pliki cookies nie są zapisywane na Twoim urządzeniu ani Vimeo nie gromadzi jakichkolwiek informacji na Twój temat, dopóki nie odtworzysz nagrania.</p>
<p>Gdy odtwarzasz nagranie, Vimeo zapisuje na Twoim urządzeniu pliki cookies i otrzymuje informacje, że odtworzyłeś nagranie z poziomu określonej strony internetowej, nawet jeśli nie posiadasz konta Vimeo czy nie jesteś akurat zalogowany. Jeśli zalogowałeś się do konta Vimeo, to usługodawca ten będzie mógł bezpośrednio przyporządkować wizytę na naszej stronie do Twojego konta. Cel i zakres gromadzenia danych oraz ich dalszego przetwarzania i wykorzystania przez Vimeo, jak również możliwość kontaktu oraz Twoje prawa w tym zakresie i możliwość dokonania ustawień zapewniających ochronę Twojej prywatności zostały opisane w polityce prywatności Vimeo.</p>
<p>Jeśli nie chcesz, aby Vimeo przyporządkowywał dane zebrane w trakcie odtwarzania wideo bezpośrednio Twojemu profilowi, przed odtworzeniem wideo musisz się wylogować ze swojego konta. Możesz również całkowicie uniemożliwić załadowanie na stronie wtyczek stosując odpowiednie rozszerzenia dla Twojej przeglądarki, np. blokowanie skryptów.</p>
<p>Informacje gromadzone w ramach plików cookies związanych z osadzonymi na naszych stronach filmami z serwisu Vimeo wykorzystywane są przez Vimeo w celu zapewnienia prawidłowego i bezpiecznego funkcjonowania widgetu, analizy i optymalizacji w zakresie świadczonych przez YouTube usług, jak również w celach personalizacji i reklamy.</p>
<p>Pamiętaj, że odtwarzając nagrania dostępne w serwisie Vimeo, korzystasz z usług świadczonych drogą elektroniczną przez Vimeo INC, 555 West 18th Street, Nowy Jork, Nowy Jork 10011 jest samodzielnym, niezależnym od nas podmiotem świadczącym na Twoją rzecz usługi drogą elektroniczną. Szczegółów w zakresie zasad korzystania z Vimeo, w tym ochrony prywatności, możesz szukać w dokumentach udostępnianych bezpośrednio przez Vimeo:</p>
<p>regulamin: https://www.vimeo/terms,</p>
<p>polityka prywatności: https://vimeo.comm/privacy.</p>
<p># 17: Czy śledzimy Twoje zachowania podejmowane w ramach naszej strony?<br />
Tak, korzystamy z narzędzi Google Analytics, Google AdWords, Hotjar oraz Facebook Custom Audiences, które wiążą się z gromadzeniem informacji na temat Twoich aktywności na naszej stronie. Narzędzia te zostały szczegółowo opisane w ramach pytania poświęconego plikom cookies podmiotów trzecich, dlatego nie będziemy powtarzać tych informacji również tutaj.</p>
<p># 18: Czy kierujemy do Ciebie targetowane reklamy?<br />
Tak, korzystamy z Facebook Ads oraz Google Ads, w ramach których możemy kierować reklamy do określonych grup docelowych zdefiniowanych w oparciu o różne kryteria takie jak wiek, płeć, zainteresowania, zawód, praca, działania podejmowane uprzednio w ramach naszej strony. Narzędzia te zostały szczegółowo opisane w ramach pytania poświęconego plikom cookies podmiotów trzecich, dlatego nie będziemy powtarzać tych informacji również tutaj.</p>
<p># 19: W jaki sposób możesz zarządzać swoją prywatnością?<br />
Odpowiedź na to pytanie znajduje się w wielu miejscach tej polityki prywatności przy okazji opisywania poszczególnych narzędzi, reklamy behawioralnej, zgody na cookies itp. Niemniej jednak, dla Twojej wygody zebraliśmy jeszcze raz te informacji w jednym miejscu. Poniżej znajdziesz listę możliwości zarządzania swoją prywatnością.</p>
<p>ustawienia cookies w ramach przeglądarki internetowej,<br />
wtyczki do przeglądarek wspierające zarządzanie plikami cookies np. Ghostery,<br />
dodatkowe oprogramowanie zarządzające plikami cookies,<br />
tryb incognito w przeglądarce internetowej,<br />
ustawienia reklamy behawioralnej, np. youronlinechoices.com,<br />
mechanizm zarządzania plikami cookies z poziomu naszej strony,<br />
Google Analytics Opt-out:<br />
Google Ads Settings: https://adssettings.google.com/<br />
Facebook Ads Settings: https://www.facebook.com/ads/settings<br />
HotJar Opt-out: https://www.hotjar.com/legal/compliance/opt-out<br />
# 20: Czym są logi serwera?<br />
Korzystanie ze strony wiąże się z przesyłaniem zapytań do serwera, na którym przechowywana jest strona. Każde zapytanie skierowane do serwera zapisywane jest w logach serwera.</p>
<p>Logi obejmują m.in. Twój adres IP, datę i czas serwera, informacje o przeglądarce internetowej i systemie operacyjnym z jakiego korzystasz. Logi zapisywane i przechowywane są na serwerze.</p>
<p>Dane zapisane w logach serwera nie są kojarzone z konkretnymi osobami korzystającymi ze strony i nie są wykorzystywane przez nas w celu Twojej identyfikacji.</p>
<p>Logi serwera stanowią wyłącznie materiał pomocniczy służący do administrowania stroną, a ich zawartość nie jest ujawniana nikomu poza osobami upoważnionymi do administrowania serwerem.</p>
<p># 21: Czy jest jeszcze coś, o czym powinieneś wiedzieć?<br />
Jak widzisz, tematyka przetwarzania danych osobowych, wykorzystywania plików cookies i zarządzania ogólnie pojętą prywatnością jest dość skomplikowana. Dołożyliśmy wszelkich starań by niniejszy dokument dostarczył Ci jak najdalej idącej wiedzy w ważnych dla Ciebie kwestiach. Jeżeli cokolwiek jest dla Ciebie niejasne, chcesz dowiedzieć się więcej lub po prostu porozmawiać o Twojej prywatności, napisz do nas na adres biuro@genfactor.pl</p>
		

    </div>
  )
}
