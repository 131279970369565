import { useState } from "react";
import Axios from "axios";

import { apiUrl } from "../Vars.jsx";

export const useValidateTaxNumber = (
  userCountry,
  taxNumber,
  setIdle,
  setTaxErrorMsg
) => {
  const [taxNumberIsValid, setTaxNumberIsValid] = useState();

  const validateTaxNumber = () => {
    setIdle(true);
    Axios.post(`${apiUrl}/api/validation/company`, {
      number: taxNumber,
      country: userCountry,
    }).then((response) => {
      setIdle(false);
      if (response.data === true) {
        setTaxNumberIsValid(true);
      } else if (response.data === false) {
        setTaxNumberIsValid(false);
        setTaxErrorMsg("Podany numer NIP/VAT jest niepoprawny");
      } else if (response.data === "Error") {
        setTaxNumberIsValid(false);
        setTaxErrorMsg(
          "Serwer VIES nie odpowiada, spróbuj ponownie za kilka minut"
        );
      }
      console.log(response);
    });
  };

  return { taxNumberIsValid, validateTaxNumber };
};
