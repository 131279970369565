import Axios from "axios"

import { apiUrl } from "../Vars";
import { useEffect, useState } from "react";

export default function StartDiscount({userObject, setDiscount, setActiveDiscount, setDiscountValue}) {

  const [ active, setActive ] = useState(false)

  const checkDiscount = () => {
    Axios.post(`${apiUrl}/api/start-discount-validate`, {
      discount: 'start',
      email: userObject.user.email
    })
      .then(res => {
        console.log(res.data.startDiscount)
        if (res.data.startDiscount === 'possible') {
          const discountObject = {
            name: res.data.name,
            type: res.data.type,
            value: res.data.value,
            uses_count: res.data.uses_count,
            for_users: res.data.for_users,
            require_product: res.data.require_product,
            cart_value: res.data.cart_value,
            effect :res.data.effect,
            expire: res.data.expire
          }
          setDiscount('start')
          setActiveDiscount(true)
          setDiscountValue(parseInt(res.data.value))
          setActive(true)
          sessionStorage.setItem("discount", JSON.stringify(discountObject))
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    checkDiscount()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="start-discount m-auto">
      {active ? <p className="t-center m-auto">Dziękujemy za zainteresowanie naszą ofertą! Na przywitanie mamy dla Ciebie prezent, <strong>15% rabatu</strong> na pierwsze zamówienie</p> : <></>}
    </div>
  )
}
