import { NavLink } from "react-router-dom"

import LogoDark from "../../components/Layout/LogoDark";
import ResetForm from "../../components/ResetForm";

export default function Reset() {
  
  return (
    <div className="login__page">
      <div className="form__card mt2">
        <LogoDark />
        <br /> 
        <ResetForm />
        <br />
        <p>Nie masz jeszcze konta?</p>
        <p><NavLink to='/rejestracja'>Zarejestruj się za darmo!</NavLink></p>
        <br />
        <NavLink to='/'><button className="btn">Wróć do sklepu</button></NavLink>
      </div>
    </div>
  )
}
