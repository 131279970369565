

export default function DevStateBar() {

  const devState = process.env.REACT_APP_DEVELOPER_STATE

  console.log(devState)
  console.log(typeof(devState))

  return (
    <>
      {devState === 'true' ? <div className="w-full bg-red-700 text-white h-1 absolute"></div> : <></>}
    </> 
  )
}
