import { useEffect, useState } from 'react';
import Axios from 'axios'
import { apiUrl } from "../Vars";

const GetData = (dataUrl) => {

  const [ data, setData ] = useState()
  const [ pennding, setPennding] = useState(true)
  const [ error, setError ] = useState(null)

  useEffect(() => {
    Axios.get(`${apiUrl}/api/${dataUrl}`, {
      headers: {
        "x-access-token" : `${sessionStorage.getItem("token")}`
      }, 
      withCredentials: true
    })
      .then(res => {
        setData(res.data);
        setPennding(false);
        setError(null);
      })
      .catch(err => {
        console.log(err)
        setPennding(false);
        setError(err.message);
      })
  }, [dataUrl])

  return { data, pennding, error }

}

const usePostData = () => {

  const [response, setResponse] = useState(null)
  const [idle, setIdle] = useState(false)
  const [error, setError] = useState(null)

  const makePostRequest = (dataUrl, data) => {
    setIdle(true)
    Axios.post(`${apiUrl}/api/${dataUrl}`, {
      data
      }, {
        headers: {
          "x-access-token" : `${sessionStorage.getItem("token")}`
        }, 
        withCredentials: true
      })
      .then(res => {
        setIdle(false)
        setResponse(res)
        console.log(res)
      })
      .catch(err => {
        setError(err)
        console.log(err)
      })
  }
  

  return {response, idle, error, makePostRequest }

}

export { GetData, usePostData }