import SkeletonElement from "./SkeletonElement";


export default function CardSkeleton() {
  return (
    <div className="skeleton__card">
      <SkeletonElement elementClass={'thumb'} />
      <SkeletonElement elementClass={'title'} />
      <SkeletonElement elementClass={'btn'} />
    </div>
  )
}
