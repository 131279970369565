import { NavLink } from "react-router-dom"
import { useSearchParams } from 'react-router-dom'

import LogoDark from "../../components/Layout/LogoDark";
import LoginForm from "../../components/LoginForm";

export default function LoginReferral() {

  const [ params ] = useSearchParams()
  const redirect = (params.get('location'))
  

  return (
    <div className="login__page">
      <div className="form__card mt2">
        <LogoDark />
        <br /> 
        <LoginForm location={redirect} />
        <br />
        <p>Nie masz jeszcze konta?</p>
        <p className=""><NavLink to='/rejestracja'><strong>Zarejestruj się za darmo!</strong></NavLink></p>
        <br />
        <NavLink to='/'><button className="btn">Wróć do sklepu</button></NavLink>
      </div>
    </div>
  )
}
