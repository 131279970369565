import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { useCart } from '../../controllers/Cart'


import { BsPersonCircle } from "react-icons/bs";
import { FaShoppingCart } from "react-icons/fa";
import CartOnHover from "./CartOnHover";

export default function Icons({ location }) {

  const userCart = useCart()

  const [itemsInCart, setItemsInCart ] = useState()
  const [ cartOnHover, setCartOnHover ] = useState(false)

  useEffect(() => {
    setItemsInCart(userCart.length)
  }, [userCart])

  return (
    <div className={`icons ${location}`}>
      <NavLink to='/moje-konto'><BsPersonCircle /></NavLink>
      <NavLink to='/koszyk'>
        <div className="cart-icon" onMouseEnter={() => setCartOnHover(true)} onMouseLeave={() => setCartOnHover(false)}>
          <FaShoppingCart />
          {itemsInCart === 0 ? <></> : <span className="items-number">{itemsInCart}</span>}
        </div>
      </NavLink>
      <div onMouseEnter={() => setCartOnHover(true)} onMouseLeave={() => setCartOnHover(false)}>
        {cartOnHover && <CartOnHover userCart={userCart}/>}
      </div>
    </div>
  )
}
