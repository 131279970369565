
import CardSkeleton from "./CardSkeleton";

export default function GridSkleton() {
  return (
    <div className="skeleton__grid">
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
    </div>
  )
}

