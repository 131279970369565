import { string, z, literal } from "zod";

const defaultShema = z.object({
  // email: string().email({ message: "Podaj poprawny adres email" }),
  // repeatEmail: string().email(),
  name: string().min(1, { message: "Pole jest wymagane" }),
  surname: string().min(1, { message: "Pole jest wymagane" }),
  street: string().min(1, { message: "Pole jest wymagane" }),
  addressNumber1: string().min(1, { message: "Pole jest wymagane" }),
  addressNumber2: string().optional(),
  city: string().min(1, { message: "Pole jest wymagane" }),
  zipCode: string().min(1, { message: "Pole jest wymagane" }),
  country: string().min(1, { message: "Wybierz swój kraj" }),
  phone: string().min(1, { message: "Pole jest wymagane" }),
  payment_method: string({
    invalid_type: "Proszę wybrać metodę płatności",
  }).min(1, {
    errorMap: () => ({ invalid_type: "Proszę wybrać metodę płatności" }),
  }),
  orderInfo: string().optional(),
  terms: literal(true),
  data_processing: literal(true),
});

const shippingShema = z.object({
  // email: string().email({ message: "Podaj poprawny adres email" }),
  // repeatEmail: string().email(),
  name: string().min(1, { message: "Pole jest wymagane" }),
  surname: string().min(1, { message: "Pole jest wymagane" }),
  street: string().min(1, { message: "Pole jest wymagane" }),
  addressNumber1: string().min(1, { message: "Pole jest wymagane" }),
  addressNumber2: string().optional(),
  city: string().min(1, { message: "Pole jest wymagane" }),
  zipCode: string().min(1, { message: "Pole jest wymagane" }),
  country: string().min(1, { message: "Wybierz swój kraj" }),
  phone: string().min(1, { message: "Pole jest wymagane" }),
  orderInfo: string().optional(),
  payment_method: string().min(1, {
    message: "Proszę wybrać metodę płatności",
  }),
  shippingName: string().min(1, { message: "Pole jest wymagane" }),
  shippingSurname: string().min(1, { message: "Pole jest wymagane" }),
  shippingStreet: string().min(1, { message: "Pole jest wymagane" }),
  shippingAddressNumber1: string().min(1, { message: "Pole jest wymagane" }),
  shippingAddressNumber2: string().optional(),
  shippingCity: string().min(1, { message: "Pole jest wymagane" }),
  shippingZipCode: string().min(1, { message: "Pole jest wymagane" }),
  shippingCountry: string().min(1, { message: "Pole jest wymagane" }),
  shippingPhone: string().min(1, { message: "Pole jest wymagane" }),
  terms: literal(true),
  data_processing: literal(true),
});

const companyShema = z.object({
  // email: string().email({ message: "Podaj poprawny adres email" }),
  // repeatEmail: string().email(),
  name: string().min(1, { message: "Pole jest wymagane" }),
  surname: string().min(1, { message: "Pole jest wymagane" }),
  companyName: string().min(1, { message: "Pole jest wymagane" }),
  taxNumber: string().min(1, { message: "Pole jest wymagane" }),
  street: string().min(1, { message: "Pole jest wymagane" }),
  addressNumber1: string().min(1, { message: "Pole jest wymagane" }),
  addressNumber2: string().optional(),
  city: string().min(1, { message: "Pole jest wymagane" }),
  country: string().min(1, { message: "Wybierz swój kraj" }),
  zipCode: string().min(1, { message: "Pole jest wymagane" }),
  phone: string().min(1, { message: "Pole jest wymagane" }),
  orderInfo: string().optional(),
  payment_method: string().min(1, {
    message: "Proszę wybrać metodę płatności",
  }),
  terms: literal(true),
  data_processing: literal(true),
});

const shippingCompanyShema = z.object({
  // email: string().email({ message: "Podaj poprawny adres email" }),
  // repeatEmail: string().email(),
  name: string().min(1, { message: "Pole jest wymagane" }),
  surname: string().min(1, { message: "Pole jest wymagane" }),
  companyName: string().min(1, { message: "Pole jest wymagane" }),
  taxNumber: string().min(1, { message: "Pole jest wymagane"} ),
  street: string().min(1, { message: "Pole jest wymagane" }),
  addressNumber1: string().min(1, { message: "Pole jest wymagane" }),
  addressNumber2: string().optional(),
  city: string().min(1, { message: "Pole jest wymagane" }),  
  country: string().min(1, { message: "Wybierz swój kraj"} ),
  zipCode: string().min(1, { message: "Pole jest wymagane" }),
  phone: string().min(1, { message: "Pole jest wymagane" }),
  orderInfo: string().optional(),
  payment_method: string().min(1, {
    message: "Proszę wybrać metodę płatności",
  }),
  shippingName: string().min(1, { message: "Pole jest wymagane" }),
  shippingSurname: string().min(1, { message: "Pole jest wymagane" }),
  shippingCompanyName: string().optional(),
  shippingStreet: string().min(1, { message: "Pole jest wymagane" }),
  shippingAddressNumber1: string().min(1, { message: "Pole jest wymagane" }),
  shippingAddressNumber2: string().optional(),
  shippingCity: string().min(1, { message: "Pole jest wymagane" }),
  shippingZipCode: string().min(1, { message: "Pole jest wymagane" }),
  shippingCountry: string().min(1, { message: "Pole jest wymagane" }),
  shippingPhone: string().min(1, { message: "Pole jest wymagane" }),
  terms: literal(true),
  data_processing: literal(true),
});

// .refine(async (data) => {
//   const number = data.taxNumber
//   const country = data.country
//   const result = await Axios.post(`${apiUrl}/api/validation/company`, { number: number, country: country })
//   console.log(result.data)
//   return result.data
// }, {
//   message: 'Podaj poprawny numer NIP/VAT',
//   path: ['taxNumber']
// })

const shippingCompanyUpdateShema = z.object({
  name: string().min(1, { message: "Pole jest wymagane" }),
  surname: string().min(1, { message: "Pole jest wymagane" }),
  companyName: string().min(1, { message: "Pole jest wymagane" }),
  taxNumber: string().min(1, { message: "Pole jest wymagane" }),
  street: string().min(1, { message: "Pole jest wymagane" }),
  addressNumber1: string().min(1, { message: "Pole jest wymagane" }),
  addressNumber2: string().optional(),
  city: string().min(1, { message: "Pole jest wymagane" }),
  zipCode: string().min(1, { message: "Pole jest wymagane" }),
  country: string().min(1, { message: "Pole jest wymagane" }),
  phone: string().min(1, { message: "Pole jest wymagane" }),
  orderInfo: string().optional(),
  shippingName: string().min(1, { message: "Pole jest wymagane" }),
  shippingSurname: string().min(1, { message: "Pole jest wymagane" }),
  shippingCompanyName: string().optional(),
  shippingStreet: string().min(1, { message: "Pole jest wymagane" }),
  shippingAddressNumber1: string().min(1, { message: "Pole jest wymagane" }),
  shippingAddressNumber2: string().optional(),
  shippingCity: string().min(1, { message: "Pole jest wymagane" }),
  shippingZipCode: string().min(1, { message: "Pole jest wymagane" }),
  shippingCountry: string().min(1, { message: "Pole jest wymagane" }),
  shippingPhone: string().min(1, { message: "Pole jest wymagane" }),
});

const shippingUpdateShema = z.object({
  name: string().min(1, { message: "Pole jest wymagane" }),
  surname: string().min(1, { message: "Pole jest wymagane" }),
  street: string().min(1, { message: "Pole jest wymagane" }),
  addressNumber1: string().min(1, { message: "Pole jest wymagane" }),
  addressNumber2: string().optional(),
  city: string().min(1, { message: "Pole jest wymagane" }),
  zipCode: string().min(1, { message: "Pole jest wymagane" }),
  country: string().min(1, { message: "Pole jest wymagane" }),
  phone: string().min(1, { message: "Pole jest wymagane" }),
  orderInfo: string().optional(),
  shippingName: string().min(1, { message: "Pole jest wymagane" }),
  shippingSurname: string().min(1, { message: "Pole jest wymagane" }),
  shippingStreet: string().min(1, { message: "Pole jest wymagane" }),
  shippingAddressNumber1: string().min(1, { message: "Pole jest wymagane" }),
  shippingAddressNumber2: string().optional(),
  shippingCity: string().min(1, { message: "Pole jest wymagane" }),
  shippingZipCode: string().min(1, { message: "Pole jest wymagane" }),
  shippingCountry: string().min(1, { message: "Pole jest wymagane" }),
  shippingPhone: string().min(1, { message: "Pole jest wymagane" }),
});

const registerShema = z
  .object({
    email: string()
      .email({ message: "Podaj poprawny adres email" })
      .min(1, { message: "Podaj poprawny adres email" }),
    repeatEmail: string().min(1, { message: "Pole jest wymagane" }),
    password: string().min(8, {
      message: "Hasło powinno mieć conajmniej 8 znaków",
    }),
    repeatPassword: string().min(1, { message: "Pole jest wymagane" }),
    taxNumber: string().min(1, { message: "Pole jest wymagane" }),
    country: string().min(1, { message: "Pole jest wymagane" }),
  })
  .refine((data) => data.email === data.repeatEmail, {
    message: "Podane adresy email nie zgadzają się.",
    path: ["repeatEmail"],
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: "Podane hasła nie zgadzają się",
    path: ["repeatPassword"],
  });
// .refine(async (data) => {
//   const number = data.taxNumber
//   const country = data.country
//   const result = await Axios.post(`${apiUrl}/api/validation/company`, { number: number, country: country })
//   console.log(result.data)
//   return result.data
// }, {
//   message: 'Podaj poprawny numer NIP/VAT',
//   path: ['taxNumber']
// })

const resetShema = z
  .object({
    password: string().min(8, {
      message: "Hasło powinno mieć conajmniej 8 znaków",
    }),
    repeatPassword: string().min(1, { message: "Pole jest wymagane" }),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: "Podane hasła nie zgadzają się",
    path: ["repeatPassword"],
  });

const ticketShema = z.object({
  email: string()
    .email({ message: "Podaj poprawny adres email" })
    .min(1, { message: "Podaj poprawny adres email" }),
  phone: string().min(1, { message: "Pole jest wymagane" }),
  description: string().min(1, { message: "Pole jest wymagane" }),
  page: string().min(1, { message: "Pole jest wymagane" }),
});

export {
  companyShema,
  defaultShema,
  shippingShema,
  shippingCompanyShema,
  shippingCompanyUpdateShema,
  shippingUpdateShema,
  registerShema,
  resetShema,
  ticketShema,
};
