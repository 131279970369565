import { NavLink } from 'react-router-dom'

import { motion } from 'framer-motion'

import { useDispatchCart } from '../controllers/Cart';
import { useLanguage } from '../controllers/Language';
import Button from './Layout/Button';

export default function ProductCard({product, productInCart, userLoggedIn, type}) {

  const dispatch = useDispatchCart()

  const Language = useLanguage()

  let brutto = 0
  let netto = 0

  if (product.category === "Personal Care" || product.id === 139) {
    switch (type) {
      case 'partner30':
        brutto = product.brutto_a
        netto = product.netto_a
        break;
      case 'client':
        brutto = product.brutto_c
        netto = product.netto_c
        break;
      default:
        brutto = product.brutto
        netto = product.netto
        break;
    }
  } else {
    switch (type) {
      case 'partner30':
        brutto = product.brutto_a
        netto = product.netto_a
        break;
      default:
        brutto = product.brutto
        netto = product.netto
        break;
    }
  }

  const cartPayload = {
    productId: product.id,
    name: product.name,
    short_name: product.short_name,
    brutto: brutto,
    netto: netto,
    vat: product.vat,
    ref_number: product.ref_number,
    quantity: 1,
    weight: product.weight
  }

  const addToCart = (item) => {
    dispatch({ type: "ADD", item })
  }

  const variants = {
    active: {
      scale: .95,
      rotate: -3,
      transition: {
        duration: 0.9,
        type: "spring", 
        stiffness: 100
      }
    }
  }
  

  return (
    <>
      {userLoggedIn ?
        <>
        {!product && !brutto ? <h3>...</h3> : <>
        <div className="card__top">
          <NavLink to={`/produkt/${product.id}`}>
            <motion.div className='thumbnail' style={{ backgroundImage: `url(${product.main_img})` }} whileHover='active' variants={variants}></motion.div>
          </NavLink>
          <div className="card__heading">
            <NavLink to={`/produkt/${product.id}`}>
              <h3 className="card__product__name">{product.name}</h3>
            </NavLink>
            {!product.brutto ? <h3>...</h3> : <h3 className={`product__price ${Language}`}>{brutto}</h3>}
          </div>
        </div>
        <div className="product__btn flex-between">
          {!productInCart ? 
          <Button content={'Dodaj do Koszyka'} action={addToCart} option={cartPayload}/> :
          <NavLink to={`/koszyk`} className='w100'><button className="btn-alert w100">Zobacz koszyk</button></NavLink>}
        </div>
      </>}
      </> : 
      <>
        {!product ? <h3>...</h3> : <>
        <div className="card__top">
          <NavLink to={`/produkt/${product.id}`}>
            <motion.div className='thumbnail' style={{ backgroundImage: `url('${product.main_img}')` }} whileHover='active' variants={variants}></motion.div>
          </NavLink>
          <div className="card__heading">
            <NavLink to={`/produkt/${product.id}`}>
              <h3 className="card__product__name">{product.name}</h3>
            </NavLink>
          </div>
        </div>
        <div className="product__btn flex-between">
          <NavLink to={`/login`} className='w100'><button className="btn-alert w100">Zaloguj się aby zobaczyć ceny</button></NavLink>
        </div>
      </>}
      </>
      }
    </>
  )
}
