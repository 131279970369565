export default function DatePicker({ fromDate, setFromDate, toDate, setToDate, filterAction }) {

  return (
    <div className="flex items-center gap-4">

       <div className="flex gap-4">
          <label htmlFor="from">Od:</label>
          <input type="date" id="from" value={fromDate} onChange={(e) => {setFromDate(e.target.value);}}/>
        </div>

        <div className="flex gap-4">
          <label htmlFor="to">Do:</label>
          <input type="date" id="to" value={toDate} onChange={(e) => {setToDate(e.target.value);}}/>
        </div>

        <div className="">
          <button className="bg-tl-primary px-6 py-1 text-tl-light hover:bg-tl-secondary" onClick={() => filterAction()}>Filtruj</button>
        </div>

    </div>
  )
}
