import { useDispatchLanguage } from "../controllers/Language";
import { useLanguage } from "../controllers/Language";

export default function LanguageSwitcher() {
  const languages = ["pl"];

  const activeLanguage = useLanguage();

  const dispatch = useDispatchLanguage();

  const selectLanguage = (type) => {
    dispatch({ type: type });
  };

  return (
    <div className="language__switcher">
      <div className="language__active">
        <div className="language__icon">
          <img
            src={`../assets/flags/${activeLanguage}.png`}
            alt={activeLanguage}
          />
        </div>
      </div>
      <div className="language__inner">
        {languages.map((i) => (
          <div
            className="language__icon"
            onClick={() => selectLanguage(i)}
            key={i}
          >
            <img
              src={`../assets/flags/${i}.png`}
              alt={i}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
