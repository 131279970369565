import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Axios from "axios";

import { useForm } from "react-hook-form";

import { registerShema } from "../controllers/ValidationShemas";

import FormInput from "./FormInput";
import FormInputTax from "./FormInputTax";
import FormSelect from "./FormSelect";

import { apiUrl } from "../Vars";
// import { response } from 'express'

export default function RegisterForm() {
  const [errMsg, setErrMsg] = useState(null);
  const [taxNumberInput, setTaxNumberInput] = useState();
  const [countryInput, setCountryInput] = useState();
  const [taxNumberIsValid, setTaxNumberIsValid] = useState();
  const [taxErrorMsg, setTaxErrorMsg] = useState();
  const [idle, setIdle] = useState(false);

  const [params] = useSearchParams();
  const isRef = params.get("ref");
  const refParam = params.get("param");

  const { register, handleSubmit, formState } = useForm({ resolver: zodResolver(registerShema), });

  const { errors } = formState;

  const countries = [
    { value: "" }, { name: "Polska", value: "PL" }, { name: "Wielka Brytania", value: "GB" }, { value: "AT", name: "Austria" }, { value: "BE", name: "Belgia" }, { value: "BG", name: "Bułgaria" }, { value: "HR", name: "Chorwacja" }, { value: "CY", name: "Cypr" }, { value: "CZ", name: "Czechy" }, { value: "DK", name: "Dania" }, { value: "EE", name: "Estonia" }, { value: "FI", name: "Finlandia" }, { value: "FR", name: "Francja" }, { value: "GR", name: "Grecja" }, { value: "ES", name: "Hiszpania" }, { value: "IE", name: "Irlandia" }, { value: "LT", name: "Litwa" }, { value: "LU", name: "Luksemburg" }, { value: "LV", name: "Łotwa" }, { value: "MT", name: "Malta" }, { value: "NL", name: "Holandia" }, { value: "DE", name: "Niemcy" }, { value: "PT", name: "Portugalia" }, { value: "RO", name: "Rumunia" }, { value: "SK", name: "Słowacja" }, { value: "SI", name: "Słowenia" }, { value: "SE", name: "Szwecja" }, { value: "HU", name: "Węgry" }, { value: "IT", name: "Włochy" },
  ];

  const email = { name: "email", label: "Adres email", options: "" };
  const repeatEmail = { name: "repeatEmail", label: "Powtórz adres email", options: "", };
  const password = { name: "password", label: "Podaj swoje hasło", options: "", type: "password", };
  const repeatPassword = { name: "repeatPassword", label: "Powtórz swoje hasło", options: "", type: "password", };
  const taxNumber = { name: "taxNumber", label: "Numer NIP lub VAT", options: "", type: "text", };
  const country = { name: "country", label: "Kraj", options: countries };

  const navigate = useNavigate();

  const submitUser = (data) => {
    console.log(data);
    Axios.post(`${apiUrl}/api/register`, {
      email: data.email,
      password: data.password,
      isRef: isRef,
      refParam: refParam,
    })
      .then((response) => {
        console.log(response);
        if (
          response.data === "Użytkownik o podanym adresie email już istnieje"
        ) {
          setErrMsg(response.data);
        }
        if (response.data === "User created") {
          setErrMsg(null);
          navigate("/login?msg=register-ok");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateTaxNumber = () => {
    setTaxErrorMsg(null);
    console.log(countryInput, taxNumberInput);
    if (!taxNumberInput && !countryInput) {
      setTaxErrorMsg("Wybierz swój kraj i podaj numer NIP/VAT");
      return;
    } else if (!taxNumberInput) {
      setTaxErrorMsg("Podaj numer NIP/VAT");
      return;
    } else if (!countryInput) {
      setTaxErrorMsg("Wybierz swój kraj");
      return;
    }
    setIdle(true);
    Axios.post(`${apiUrl}/api/validation/company`, {
      number: taxNumberInput,
      country: countryInput,
    }).then((response) => {
      setIdle(false);
      if (response.data === true) {
        setTaxNumberIsValid(true);
      } else if (response.data === false) {
        setTaxNumberIsValid(false);
        setTaxErrorMsg("Podany numer NIP/VAT jest niepoprawny");
      } else if (response.data === "Error") {
        setTaxNumberIsValid(false);
        setTaxErrorMsg(
          "Serwer VIES nie odpowiada, spróbuj ponownie za kilka minut"
        );
      }
      console.log(response);
    });
  };

  const showError = (errors) => {
    console.log(errors);
  };

  return (
    <div>
      {errMsg !== null ? <p style={{ color: "red" }}>{errMsg}</p> : <></>}
      <form
        action="/"
        className="form"
        onSubmit={handleSubmit(submitUser, showError)}
      >
        <FormInput controls={email} register={register} />
        <span className="input__err">{errors.email?.message}</span>
        <FormInput controls={repeatEmail} register={register} />
        <span className="input__err">{errors.repeatEmail?.message}</span>
        <FormInput controls={password} register={register} />
        <span className="input__err">{errors.password?.message}</span>
        <FormInput controls={repeatPassword} register={register} />
        <span className="input__err">{errors.repeatPassword?.message}</span>
        <FormSelect controls={country} register={register} countryChange={setCountryInput} />
        <span className="input__err">{errors.country?.message}</span>
        <div className="tax-number-input">
          <FormInputTax controls={taxNumber} register={register} onChange={setTaxNumberInput} />
          {taxNumberIsValid ? (
            <div className="button-validation btn-disabled">OK</div>
          ) : (
            <div className="button-validation btn-active" onClick={() => validateTaxNumber()} > OK </div>
          )}
        </div>
        <span className="input__err">{errors.taxNumber?.message}</span>
        <span className="input__err">{taxErrorMsg}</span>
        {idle ? (
          <span className="input__err idle-tax">Sprawdzanie...</span>
        ) : (
          <></>
        )}
        <p className="field-description">
          Wybierz kraj, wpisz numer NIP/VAT bez przedrostka swojego kraju, a
          następnie zatwierć przyciskiem "OK"
        </p>
        <br />
        {taxNumberIsValid ? (
          <button type="submit" className="btn"> Zarejestruj się </button>
        ) : (
          <button disabled className="btn btn-disable"> Zarejestruj się </button>
        )}
      </form>
      <br />
      <p> Rejestrując się w naszym sklepie oświadczasz równocześnie, że zapoznałeś/-aś się z warunkami świadczenia usług i regulaminem, który możesz znaleźć tutaj: </p>
      <p>
        <strong> <NavLink to="/terms">Regulamin i polityka prywatności</NavLink> </strong>
      </p>
    </div>
  );
}
