import { useState } from "react"
import { useNavigate } from "react-router"
import Axios from "axios"

import { apiUrl } from "../Vars"

export default function ResetForm({location, action}) {

  const [ useremail, setUseremail ] = useState('')
  // const [ err, setErr ] = useState()

  const navigate = useNavigate()

  const reset = (e) => {
    e.preventDefault()
    Axios.post(`${apiUrl}/api/reset-password`, {
      email: useremail
    }, {
      baseURL: apiUrl,
      withCredentials: true
    }).then((response) => {
      navigate('/')
    }).catch((err) => {
      console.log(err)
    })
  };

  return (
    <div className={location}>
      <form action="/" className="form">
        <div className="login__box">
          <div>
            <label htmlFor="email">Twój email</label>
            <input type="text" id="email" name="email" onChange={(e) => {setUseremail(e.target.value)}}/>
          </div>
        </div>
        <button type="submit" className="btn" onClick={(e) => {reset(e)}}>Zresetuj hasło</button>
      </form>
    </div>
  )
}
