import { NavLink } from "react-router-dom"
import { useNavigate } from "react-router"

import { useDispatchUser } from '../../controllers/UserContext'
import { useAuth } from "../../controllers/useAuth"



export default function Navigation({ location }) {

  const navigate = useNavigate()

  const userLoggedIn = useAuth()
  const dispatch = useDispatchUser()

  const logout = () => {
    const authObject = {
      auth: null,
      token: null,
      user: {
        billingAddress : null,
        email : null,
        id : null,
        shippingAddress : null,
        userType : null
      }
    }
    dispatch({ type: "LOGOUT", authObject })
    navigate(`/`)
  }


	return(
		<div className={`navigation ${location}`}>
      <ul className="navigation__list">
        <li key='1'><NavLink to='/'>Produkty</NavLink></li>
        <li key='2'><NavLink to='/moje-konto'>Moje Konto</NavLink></li>
        <li key='3'><NavLink to='/contact'>Kontakt</NavLink></li>
        {!userLoggedIn ? <><li key='4'><NavLink to='/login'>Zaloguj się</NavLink></li><li key='5'><NavLink to='/rejestracja'>Zarejestruj się</NavLink></li></> :
        <li key='4' onClick={() => logout()}><NavLink to='/login'>Wyloguj się</NavLink></li>}
		  </ul>
    </div>
	)
}