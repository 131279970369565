import { useState, useEffect } from 'react'

import { useUser } from '../../controllers/UserContext';
import { useLanguage } from '../../controllers/Language';

import { useAuth } from "../../controllers/useAuth"

import CartItemBox from "../CartItemBox"

export default function CartOnHover({ userCart }) {

  const userObject = useUser()
  const Language = useLanguage()

  const userLoggedIn = useAuth()

  const [ cartValue, setCartValue ] = useState()

  useEffect(() => {
    if (userCart) {
      let value = 0;
      for (let i = 0; i < userCart.length; i++) {
        value = value + (parseFloat(userCart[i].brutto) * userCart[i].quantity)
      }
      setCartValue(value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCart])

  return (
    <>
      {userLoggedIn ? <>
      {!userCart ? <></> : 
          <div className='cart__box'>
            {userCart.map(cartProduct => 
              (<CartItemBox key={cartProduct.productId} cartProduct={cartProduct} type={userObject.user.userType} />
            ))}
            <div className={`cart__item__price ${Language} cart__box-summary`}>Razem: <strong>{cartValue}</strong> </div>
          </div>
        }
      </> : <></>}
    </>
    
  )
}
