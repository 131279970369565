import { useState, useEffect } from "react";

import { useAuth } from "../../controllers/useAuth";
import { useUser } from "../../controllers/UserContext";

import { BsPersonCircle } from "react-icons/bs";
import { FaRegListAlt } from "react-icons/fa";
import { FaClipboardCheck } from "react-icons/fa";

import { GetData, usePostData } from "../../controllers/fetchData.jsx";

import MyAccountData from "../../components/UserAccount/MyAccountData";
import MyAccountOrders from "../../components/UserAccount/MyAccountOrders";
import MyAccoutnUser from "../../components/UserAccount/MyAccoutnUser";
import LoginMonit from "../../components/Layout/LoginMonit";
import ReferralUser from "../../components/UserAccount/ReferralUser";

// import { FaHeart } from "react-icons/fa";
// import { FaEnvelope } from "react-icons/fa";
// import { FaGift } from "react-icons/fa";

export default function MojeKonto() {
  const userEmail = sessionStorage.getItem("userEmail");

  const userLoggedIn = useAuth();

  const userObject = useUser();

  const [user, setUser] = useState(userObject);

  const { data, pennding, error } = GetData(`ref-users/${userEmail}`);

  useEffect(() => {
    setUser(userObject.user);
  }, [userObject]);

  return (
    <>
      {!userLoggedIn ? (
        <LoginMonit location={"moje-konto"} />
      ) : (
        <div className="user__account">
          <div className="user__account__card">
            <div className="user__account__icon">
              <BsPersonCircle />
            </div>
            <h3 className="user__account__cardname">Moje konto</h3>
          </div>
          <br />
          <p>W tej sekcji znajdziesz informacje na jaki adres email założone jest konto oraz możesz zmienić lub zresetować hasło</p>
          <MyAccoutnUser user={user} />
          <div className="user__account__card">
            <div className="user__account__icon">
              <FaRegListAlt />
            </div>
            <h3 className="user__account__cardname">Moje zamówienia</h3>
          </div>
          <br />
          <p>Tutaj znajdziesz historię swoich zamówień. Możesz sprawdzić co i kiedy zamawiałeś, a także jaki jest status rozpoczętych zamówień.</p>
          <MyAccountOrders user={user} />
          <div className="user__account__card">
            <div className="user__account__icon">
              <FaClipboardCheck />
            </div>
            <h3 className="user__account__cardname">Moje dane</h3>
          </div>
          <br />
          <p>W tej sekcji sprawdzisz swoje dane rozliczeniowe a także adres do wysyłek, jeśli chcesz je zaktualizować, możesz to zrobić w formularzu zamówienia przy okazji następnego zamówienia.</p>
          <MyAccountData user={user} />
          <br />
          <br />
          {data && data.referral_user_email ? (
            <>
              <div className="user__account__card">
                <div className="user__account__icon">
                  <FaClipboardCheck />
                </div>
                <h3 className="user__account__cardname">Strefa Ambasadora</h3>
              </div>
              <p className="mt-6">
                W tym miejscu możesz zarządzać swoim kontem Ambasadora marki <strong className="text-tl-primary">Gen Factor</strong>.
              </p>
              {user && user.flags.refUser === "true" ? <ReferralUser referral_user_email={userEmail} /> : <></>}
            </>
          ) : (
            <></>
          )}
          <br />
          <br />
          {/* <div className="user__account__card">
          <div className="user__account__icon">
           <FaHeart />
          </div>
            <h3 className="user__account__cardname">Ulubione produkty</h3>
        </div>
        <br />
        <p>Stąd masz szybki dostęp do swoich ulubionych produktów.</p>
        <br />
        <br />
        <br />
        <br />
        <div className="user__account__card">
          <div className="user__account__icon">
           <FaEnvelope />
          </div>
            <h3 className="user__account__cardname">Skrzynka odbiorcza</h3>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="user__account__card">
          <div className="user__account__icon">
           <FaGift />
          </div>
            <h3 className="user__account__cardname">Program lojalnościowy</h3>
        </div> */}
        </div>
      )}
    </>
  );
}
