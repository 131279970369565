

export default function Contact() {
  return (
    <div className="contact">
      <div className="login-monit flex row j-center a-center g3 m-column">
        <div className="login-monit__form pl1 pr1">
          <h3 className="s3 primary">Chcesz się z nami skontaktować?.</h3>
          <p className="s1_5"><strong>Saintlazarus SP. z o.o.</strong></p>
          <p className="s1_5">ul. Pocztowa 4</p>
          <p className="s1_5">21-300 Radzyń Podlaski</p>
          <p className="s1_5">NIP 5381859985</p>
          <p className="s1_5 mb2">KRS 0000784131</p>
          <button className="btn"><a href="mailto:sklep@genfactor.pl">Napisz wiadomość</a></button>
        </div>
        <div className="login-monit__img">
          <img src="https://shop.genfactor.pl/assets/contact-page.png" alt="Kontakt" />
        </div>
    </div>
    </div>
  )
}
