import { useState, useEffect } from "react"

import { useGetProduct } from "../hooks/useGetProduct"
import { useLanguage } from '../controllers/Language';


export default function CartItemBox({cartProduct, type}) {

  const { prod, isPennding, err } = useGetProduct(cartProduct.productId)
  
  const Language = useLanguage()

  const [ item, setItem ] = useState()
  const [ product, setProduct ] = useState()
  const [ quantity, setQuantity ] = useState()

  let brutto = 0
  let netto = 0

  if (product) {
    if (product.category === "Personal Care" || product.id === 139) {
      switch (type) {
        case 'partner30':
          brutto = product.brutto_a
          netto = product.netto_a
          break;
        case 'client':
          brutto = product.brutto_c
          netto = product.netto_c
          break;
        default:
          brutto = product.brutto
          netto = product.netto
          break;
      }
    } else {
      switch (type) {
        case 'partner30':
          brutto = product.brutto_a
          netto = product.netto_a
          break;
        default:
          brutto = product.brutto
          // eslint-disable-next-line no-unused-vars
          netto = product.netto
          break;
      }
    }
  }
  

  useEffect(() => {
    if (prod) {
      setProduct(prod)
    }
    if (err) {
      console.log(err)
    }
  }, [prod, isPennding, err])

  useEffect(() => {
    setItem(cartProduct)
  }, [cartProduct])

  useEffect(() => {
    if (item) {
      setQuantity(parseInt(item.quantity))
    }
  }, [item])

  return (
    <>
    {isPennding || !cartProduct || !item || !product ? <h3>...</h3> : 
    <div className='cart__item'>
      <div className='cart__item__left'>
        <div className='cart__thumbnail' style={{ backgroundImage: `url('${product.main_img}')` }}></div>
        <h3>{product.name}</h3>
      </div>
      <div className='cart__item__right'>
        <h3 className='cart__item__qty'>
            {quantity} szt.
        </h3>
        {!brutto ? <h3>...</h3> : <h3 className={`cart__item__price ${Language}`}>{brutto * quantity}</h3>}
      </div>
    </div>}
    </>
  )
}
