import SkeletonElement from "./SkeletonElement";

export default function ListItemSkeleton() {
  return (
    <div className="skeleton__listitem">
      <div className="skeleton__thumb">
        <SkeletonElement elementClass={'thumb-item'} />
      </div>
      <div className="skeleton__details">
        <SkeletonElement elementClass={'title-item'} />
        <SkeletonElement elementClass={'line-item'} />
        <SkeletonElement elementClass={'line-item'} />
        <SkeletonElement elementClass={'line-item'} />
        <SkeletonElement elementClass={'line-item'} />
      </div>
      <div className="skeleton__buttons">
        <SkeletonElement elementClass={'btn-item'} />
        <SkeletonElement elementClass={'btn-item'} />
      </div>
    </div>
  )
}
