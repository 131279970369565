import ListItemSkeleton from "./ListItemSkeleton";


export default function ListSkeleton() {
  return (
    <div className="skeleton__list">
      <ListItemSkeleton />
      <ListItemSkeleton />
      <ListItemSkeleton />
      <ListItemSkeleton />
      <ListItemSkeleton />
      <ListItemSkeleton />

    </div>
  )
}
