import { NavLink } from "react-router-dom";
import { useSearchParams } from 'react-router-dom'

export default function Success() {

  const [ params ] = useSearchParams()
  const orderId = (params.get('orderId'))

  return (
    <div className="success">
      <p>Twoje zamówienie nr <strong>#{orderId}</strong> zostało przyjęte. Dziękujemy za zakupy w naszym sklepie!</p>
      <p>O zmianach w jego realizacji poinformujemy Cię mailowo, jak również możesz sprawdzić aktualny status na stronie Moje Konto</p>
      <NavLink to={`/`} className='w100'><button className="btn-alert w100 marginTop">Powrót do sklepu</button></NavLink>
    </div>
  )
}
