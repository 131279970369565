import { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import { useLocation } from "react-router-dom"
import axios from "axios"

import { useAuth } from "../../controllers/useAuth"
import { useDispatchUser } from '../../controllers/UserContext'
import { apiUrl } from "../../Vars";

export default function AuthInfo() {

  const userLoggedIn = useAuth()
  const dispatch = useDispatchUser()
  const location = useLocation()

  const [ counter, setCounter ] = useState(90)
  const [ isVisible, setIsVisible ] = useState(false)
  const [ newRefresh, setNewRefresh ] = useState(false)

  const navigate = useNavigate()

  const refreshLogin = () => {
    const token = sessionStorage.getItem("token")
    axios.get(`${apiUrl}/api/refresh-token`, {
      headers: {
        "x-access-token" : token
      }
    })
    .then((response) => {
      const loginTime = Date.now()
      console.log(response.data.token)
      sessionStorage.setItem("token", response.data.token)
      sessionStorage.setItem("loginTime", loginTime)
      setIsVisible(false)
      setNewRefresh(true)
      // console.log(authState)
    }).catch((err) => {
      console.log(err)
    })
  }

  const authObject = {
    auth: null,
    token: null,
    user: {
      billingAddress : null,
      email : null,
      id : null,
      shippingAddress : null,
      userType : null
    }
  }

  const logout = () => {
    dispatch({ type: "LOGOUT", authObject })
    setIsVisible(false)
  }

  useEffect(() => {
    if (userLoggedIn) {
      const loginTime = sessionStorage.getItem("loginTime")
      const currentTime = Date.now()
      console.log('Zalogowano')
      if (currentTime - loginTime > (1000 * 1800)) {
        dispatch({ type: "LOGOUT", authObject })
        setIsVisible(false)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn, newRefresh, location]);

  useEffect(() => {
    if (userLoggedIn) {
      setCounter(90)
      setNewRefresh(false)
      const loginTime = sessionStorage.getItem("loginTime")
      const interval = setTimeout(() => {
        const currentTime = Date.now()
        console.log('Sprawdzenie logowania')
        if (currentTime - loginTime >= (1000 * 1680)) {
          console.log('Rozpoczęcie przypomnienia')
          setIsVisible(true)
        }
      }, (1000 * 90));
      return () => clearTimeout(interval);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn, newRefresh]);

  useEffect(() => {
    if (isVisible) {
      const counting = setInterval(() => {
        setCounter(prevCounter => prevCounter - 1)
      }, 1000);
      return () => clearInterval(counting)
    }
  }, [isVisible])

  useEffect(() => {
    if (counter <= 0) {
      logout()
      navigate(`/login`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter])

  

  return (
    <>
      {isVisible &&
        <div className="authInfo__box">
          <h3>Twoja sesja wygaśnie za:</h3>
          <h3>{counter} sekund</h3>
          <button className="btn" onClick={() => refreshLogin()}>Przedłuż logowanie</button>
        </div>
      }
    </>
    
  )
}
