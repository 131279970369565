import GridSkleton from "../../components/Skeletons/GridSkeleton";
import ListSkeleton from "../../components/Skeletons/ListSkeleton";

export default function Skletons() {
  return (
    <>
      <ListSkeleton />
      <br />
      <GridSkleton />
    </>
    
  )
}

